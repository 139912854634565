import React, { useState, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";

function ZoomImageModal({ openModal, handleClose, image, sx, disabled=false }) {
  const imageRef = useRef(null);
  const [zoomFactor, setZoomFactor] = useState(1);

  const handleMouseMove = (e) => {
    let { left, top, width, height } = e.target.getBoundingClientRect();
    let xPaddingPercentage = 5
    let yPaddingPercentage = 5
    let x = ((e.pageX - left) / width) * 100;
    if (x < 50)
      {
        x -= xPaddingPercentage
      } else {
        x += xPaddingPercentage
      }
    let y = ((e.pageY - top) / height) * 100;
    if (y < 50)
    {
      y -= yPaddingPercentage
    } else {
      y += yPaddingPercentage
    }
    imageRef.current.style.backgroundPosition = `${x}% ${y}%`;
  };

  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent default context menu
    event.stopPropagation(); // Prevents the event from bubbling up to the Box

    setZoomFactor(zoomFactor === 1 ? 2 : (zoomFactor === 2 ? 3 : 1));
  };

  return (
    <Modal open={disabled !== null && disabled !== undefined ? (!disabled ? openModal : null) : openModal} onClose={disabled !== null && disabled !== undefined ? (!disabled ? handleClose : null) : handleClose} sx={sx}>
        <Box
            ref={imageRef}
            onClick={handleClose}
            onContextMenu={handleRightClick}
            sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundImage: image !== null ? `url(${image})` : `none`,
            backgroundRepeat: "no-repeat",
            width: "60vw",
            height: "80vh",
            backgroundSize: `${zoomFactor*100}%`,
            transition: "background-size 0.3s ease-in-out",
            
            }}
            onMouseMove={handleMouseMove}
        />
    </Modal>
  );
}

export default ZoomImageModal;
