import React, { useState, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
  Typography,
} from "@mui/material";
import ZoomImageModal from "../../../common/userFeedback/modals/ZoomImageModal"

function ClickableImage({ image, sxImg={} }) {
  const [columns, setColumns] = useState(5);
  const [clickedImage, setClickedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const imageRef = useRef(null);
  const { palette } = useTheme();

  const handleRightClick = (event) => {
    event.preventDefault(); // Prevent default context menu
    event.stopPropagation(); // Prevents the event from bubbling up to the Box

    setClickedImage(true);
    setOpenModal(true);
  };

  const handleClose = (event) => {
    event.stopPropagation(); // Prevents the event from bubbling up to the Box
    setClickedImage(false);
    setOpenModal(false);
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100%",
        position: "relative",
      }}
      onContextMenu={handleRightClick} // Add this line to ensure right-click is captured
    >
      {image !== null && image !== undefined
      ? <Box
        component="img"
        src={image}
        alt={`image`}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          cursor: "context-menu",
          ...sxImg
        }} // Indicate right-click is available
      />
      : <Typography variant="h4" sx={{color: palette.primary[300]}}>No image.</Typography>}

      <ZoomImageModal handleClose={handleClose} openModal={openModal} image={image} disabled={image === null || image === undefined}/>
    </Box>
  );
}

export default ClickableImage;
