import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import CustomLoadingModal from "../../../components/common/userFeedback/CustomLoadingModal";
import { BASE_URL } from "../../../config/apiConfig";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import { getHeaders } from "../../../utils/apiUtils";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import NavigationStepper from "../../../components/features/Pipelines/NavigationStepper";
import PipelineGeneralConfiguration from "./PipelineGeneralConfiguration";
import StyledButton from "../../../components/ui/buttons/StyledButton";
import SummaryDialog from "../../../components/features/Pipelines/Dialogues/SummaryDialog";

function CreatePipelineScreen(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    name,
    setPipelineId,
    pipelineSummaryRaw,
    saving,
    handleSubmit,
    pendingRequests,
  } = usePipelineContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (id) {
      setPipelineId(id);
    }
  }, [id, setPipelineId]);
  useEffect(() => {
    if (pipelineSummaryRaw) {
      setDialogOpen(true);
    }
  }, [pipelineSummaryRaw]);

  return (
    <Box
      sx={{
        padding: 1,
        paddingTop: 1,
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <SummaryDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          pipeline={pipelineSummaryRaw}
        />
        <Typography variant="h2">
          {id ? "Edit Pipeline" : "Create Pipeline"}
          <Typography color="textSecondary">{name}</Typography>
        </Typography>
        <StyledButton
          onClick={handleSubmit}
          loading={saving}
          variant="contained"
          color="primary"
          disabled={pendingRequests > 0}
        >
          Save
        </StyledButton>
      </Box>
      <PipelineGeneralConfiguration />
    </Box>
  );
}

export default CreatePipelineScreen;
