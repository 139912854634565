import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";
import { showAlert } from "../../common/userFeedback/CustomAlert";

function DatasetDropdown({ setDataset, dataset, disabled, sx }) {
  const [datasets, setDatasets] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  async function getDatasets() {
    const headers = await getHeaders();
    try {
      const res = await axios.get(
        `${BASE_URL}tools/admin/datasets/listall`,
        headers
      );

      let results = res.data.data.sort((a, b) => a.name.localeCompare(b.name));

      results = results.filter((x) => (showArchived ? x : !x.is_archived));

      setDatasets(results);
    } catch (error) {
      console.log(error);
      showAlert(error);
    }
  }
  useEffect(() => {
    getDatasets();
  }, [showArchived]);

  const handleShowArchived = async () => {
    setShowArchived(!showArchived);
  };

  return (
    <Autocomplete
      options={datasets}
      getOptionLabel={(option) => option.name}
      value={dataset}
      onChange={(event, newValue) => setDataset(newValue)}
      renderInput={(params) => (
        <Box sx={{display: "flex"}}>
          {/* ARCHIVED CHECKBOX
          
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            mr: 1,
            mt: -1
          }}>
            <Typography variant="body" sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>Archived</Typography>
            <Checkbox
              checked={showArchived}
              onChange={handleShowArchived}
              color="primary" // This is the color of the checkbox
            />
          </Box>*/}
          <TextField
            {...params}
            variant="outlined"
            label="Select dataset"
            placeholder="Datsets"
          />
        </Box>
      )}
      sx={{
        minWidth: 300,
        ...sx,
      }}
    />
  );
}

export default DatasetDropdown;
