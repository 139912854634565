import React, { createContext, useContext, useState, useEffect } from "react";

const JobConfigurationContext = createContext();

export const useJobConfigurationContext = () =>
  useContext(JobConfigurationContext);

export const JobConfigurationProvider = ({
  activeJob,
  setActiveJob,
  setUnsavedChanges,
  unsavedChanges,
  children,
  handleJobUpdate,
}) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [graduationConfidence, setGraduationConfidence] = useState(0);
  const [filters, setFilters] = useState({});
  const [labellingSet, setLabellingSet] = useState({});
  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [functionURL, setFunctionURL] = useState("");
  const [errors, setErrors] = useState({});
  const handleSave = () => {
    if (validateFields()) {
      handleJobUpdate({
        ...activeJob,
        graduationConfidence,
        filters,
        labellingSet,
        num_users: numberOfUsers,
        users: selectedUsers,
      });
    }
  };

  useEffect(() => {
    if (activeJob) {
      setGraduationConfidence(activeJob.graduationConfidence || 0);
      setFilters(activeJob.filters || {});
      setLabellingSet(activeJob.labellingSet || {});
      setNumberOfUsers(activeJob.num_users || 0);
      setSelectedUsers(activeJob.users || []);
      setFunctionURL(activeJob.functionURL || "");
    }
  }, [activeJob]);

  const validateFields = () => {
    const newErrors = {};
    if (graduationConfidence < 0 || graduationConfidence > 1) {
      newErrors.graduationConfidence =
        "Graduation confidence must be between 0 and 1.";
    }
    if (numberOfUsers <= 0) {
      newErrors.numberOfUsers = "Number of users must be greater than 0.";
    }
    if (
      !labellingSet[activeJob?.filterMap] &&
      !activeJob?.jobName == "aggregation"
    ) {
      newErrors.labellingSet = "Labelling set is required.";
    }
    if (currentScreen === 1 && !selectedUsers.length) {
      newErrors.selectedUsers = "At least one user must be selected.";
    }
    if (activeJob?.mapping_value === "function" && !functionURL) {
      newErrors.functionURL = "Function URL is required.";
    }
    console.log(labellingSet[activeJob?.mapping_value]);
    console.log(activeJob);
    console.log(newErrors);
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const value = {
    currentScreen,
    setCurrentScreen,
    graduationConfidence,
    setGraduationConfidence,
    filters,
    setFilters,
    labellingSet,
    setLabellingSet,
    numberOfUsers,
    setNumberOfUsers,
    selectedUsers,
    setSelectedUsers,
    functionURL,
    setFunctionURL,
    errors,
    validateFields,
    activeJob,
    setActiveJob,
    unsavedChanges,
    setUnsavedChanges,
    handleSave,
  };

  return (
    <JobConfigurationContext.Provider value={value}>
      {children}
    </JobConfigurationContext.Provider>
  );
};
