import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchableMultiSelect from "../../../../components/forms/Dropdowns/SearchableMultiSelect";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import useSize from "../../../../hooks/useSize";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
import { GARMENT_SUMMONING_LIMIT } from "../../../../config/summoningConfig";
const PlotlyRenderers = createPlotlyRenderers(Plot);

const CataloguingUserProgressPivotTable = forwardRef(({fillPage=false, sx={}, width=null, height=null}, ref) => {
  const [cataloguingUserProgressPivotData, setCataloguingUserProgressPivotData] = useState([]);
  const [pivotState, setPivotState] = useState({});
  const { palette, typography } = useTheme();
  const [date, setDate] = React.useState([dayjs().subtract(14, "day"), dayjs()]);
  const [dataLoading, setDataLoading] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [datasetId, setDatasetId] = useState(null);
  const [labels, setLabels] = useState([]);
  const windowSize = useSize();
  const chartWidth = fillPage ? windowSize[0] : (width ?? 0.5*windowSize[0])
  const chartHeight = fillPage ? 0.3*windowSize[1] : (height ?? 0.25*windowSize[1])
  
  const refreshData = async () => {
    setCataloguingUserProgressPivotData([]);
    setDataLoading(true);
    let cataloguingUserProgressData = []
    if (labels && labels.length > 0)
    {
      cataloguingUserProgressData = await fetchData()
    }
    setCataloguingUserProgressPivotData(cataloguingUserProgressData);
  };

  useImperativeHandle(ref, () => {
    return {
      refreshData
    }
  })

  useEffect(() => {
    const pivotState = {
      data: cataloguingUserProgressPivotData,
      rows: ['bridge_user'],
      cols: ['proposal_hour'],
      aggregatorName: 'Count Unique Values',
      vals: ['garments_seen_in_submission'],
      rendererName: 'Table',
      //sorters: {
      //    Meal: sortAs(['Lunch', 'Dinner']),
      //    'Day of Week': sortAs([
      //        'Thursday',
      //        'Friday',
      //        'Saturday',
      //        'Sunday',
      //    ]),
      //},
      plotlyOptions: {width: chartWidth, height: chartHeight}
      //plotlyConfig: {},
      //tableOptions: {
      //    clickCallback: function(e, value, filters, pivotData) {
      //        var names = [];
      //        pivotData.forEachMatchingRecord(filters, function(
      //            record
      //        ) {
      //            names.push(record.Meal);
      //        });
      //        alert(names.join('\n'));
      //    },
      //},
    }
    console.log(`cataloguingUserProgressPivotData: ${JSON.stringify(cataloguingUserProgressPivotData)}`)
    setPivotState(pivotState)
  }, [cataloguingUserProgressPivotData])

  const handleDatasetChange = (event) => {
    setDatasetId(event.target.value);
  };

  useEffect(() => {
    const fetchDefaultBrands = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/general/filteroptions/listall/BRAND?search=off`, headers)
        .then((res) => {
          // Reset form fields (optional)
          const brandOffWhite = res.data.data.filter(brand => brand.id === 5063)
          setLabels([...brandOffWhite]);
        })
        .catch((err) => {
          showAlert(err);
        });
    };

    fetchDefaultBrands();
  }, []);

  useEffect(() => {
    const fetchDatasets = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/admin/datasets/listall`, headers)
        .then((res) => {
          // Reset form fields (optional)
          setDatasets([...res.data.data.filter(x => !x.is_archived)]);
        })
        .catch((err) => {
          showAlert(err);
        });
    };

    fetchDatasets();
  }, []);

  const fetchData = async () => {
    setDataLoading(true);
    try {
      let headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/analytics/pivots/cataloguing/cataloguing-user-progress`,
        {
          indexLimit: GARMENT_SUMMONING_LIMIT,
          datasetId: datasetId,
          brandIds: labels.map(brand => brand.id),
          startDate: date[0].toISOString(),
          endDate: date[1].toISOString(),
        },
        headers
      );
      const newData = response.data.data;
      setDataLoading(false);
      console.log(`doneU`)
      return newData;
    } catch (error) {
      setDataLoading(false);
      console.error(`Error fetching proposals for Cataloguing User Progress:`, error);
      showAlert(error);
      return []; // Return whatever was fetched before the error
    }
  };

  useEffect(() => {
    refreshData()
  }, [date, datasetId, labels])

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant={fillPage ? "h2" : "h4"} sx={{
          p: 1, pt: 0
        }} color="primary.800">
          Cataloguing User Progress Pivot Table
        </Typography>
        <Box
          sx={{
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{ start: "Start", end: "End" }}
              onChange={(newValue) => setDate(newValue)}
              value={date}
            />
          </LocalizationProvider>

          {!ref && 
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={refreshData}>
              Refresh
            </Button>
          </Box>}
          
          <FormControl margin="normal" sx={{ ml: 2}}>
            <InputLabel shrink={true} id="dataset-label">
              Dataset
            </InputLabel>
            <Select
              labelId="dataset-label"
              id="dataset"
              label="Dataset"
              notched={true}
              value={datasetId}
              onChange={handleDatasetChange}
            >
              <MenuItem value={null}>None</MenuItem>
              {datasets.map((dataset) => (
                <MenuItem value={dataset.id}>{dataset.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <SearchableMultiSelect
              sx={{ml: 2}}
              typeMapping={"BRAND"}
              selected={labels}
              onSelectedChange={setLabels}
              label={"Select Brand"}
            />
        </Box>

        <Box
          sx={{
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            ...sx
          }}
        >
          <PivotTableUI
            onChange={setPivotState}
            {...pivotState}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
})

export default CataloguingUserProgressPivotTable;
