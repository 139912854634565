import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import ClickableImage from "./Images/ClickableImage";
import ClickableScrollImage from "./Images/ClickableScrollImage";
import ImageScrollPreview from "./Images/ImageScrollPreview";
import ScrollImageModal from "../../common/userFeedback/modals/ScrollImageModal";
import NoDataScreenCover from "../../common/userFeedback/NoDataScreenCover";

function SingleSelectGarmentWindow({
  selectedGarment,
  setSelectedGarment,
  disabled = false,
  additionalComponents = [],
  garments,
  imageIndex = 1,
  numberOfColumns = 6,
  scrollPreview = false,
  multiImageClickablePreview=false,
  spacing = 2,
  showNoDataScreen = false,
  showId = true,
}) {
  const { palette, typography } = useTheme();

  const [open, setOpen] = useState(false);
  const [selectedGarmentImages, setSelectedGarmentImages] = useState(null);

  const handleOpen = (garmentImages) => {
    setSelectedGarmentImages(garmentImages);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedGarmentImages(null);
    setOpen(false);
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          backgroundColor: palette.primary[200],
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          mt: 1,
          mb: 1,
          br: 2,
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
          }}
        >
          <ScrollImageModal
            handleClose={handleClose}
            openModal={open}
            images={selectedGarmentImages}
          />
          {garments.map((garment, index) => {
            const isSelected =
              selectedGarment.garment_id === garment.garment_id;
            const garmentImages = Array.from(
              { length: garment.num_images },
              (_, i) => i
            ).map(
              (num) =>
                `https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${
                  garment.uuid_public_id
                }-${num + 1}.jpg`
            );
            return (
              <Grid
                item
                xs={12 / numberOfColumns - 0.2}
                key={garment.garment_id}
                onClick={(e) => {
                  setSelectedGarment({ ...garment, index: index + 1 });
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  handleOpen(garmentImages);
                }}
                sx={{
                  mr: 1,
                  mb: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  backgroundColor: palette.primary[200],
                  borderRadius: 2,
                  p: scrollPreview ? 0 : 2,
                  pt: scrollPreview ? 0 : 2,
                  border: isSelected
                    ? "3px solid green"
                    : `1px solid ${palette.primary[300]}`,
                  maxHeight: "200px",
                  cursor: "pointer",
                }}
              >
                {!scrollPreview ? (
                  multiImageClickablePreview ? (
                    <ClickableScrollImage num_images={garmentImages.length} uuid_public_id={garment.uuid_public_id} image={`https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${garment.uuid_public_id}-1.jpg`} sxImg={{ height: 96, width: 96, paddingY: 2, float: "left" }} />
                  ) : (
                    <ClickableImage image={imageIndex > garmentImages.length ? null : garmentImages[imageIndex - 1]} />
                  )
                ) : (
                  <ImageScrollPreview
                    images={garmentImages}
                    sx={{
                      p: 1,
                      display: "inline-flex",
                      ml: "-6vw",
                      mt: "-14vh",
                      mr: "-4.5vw",
                      width: "125%",
                      mb: "-10vh",
                    }}
                    spacing={0.5}
                  />
                )}

                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 100,
                    height: 20,
                    ml: 1,
                    backgroundColor: palette.primary[100],
                  }}
                >
                  <Typography variant="body_sm">{garment.score}</Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: 100,
                    height: 20,
                    ml: 1,
                    backgroundColor: palette.primary[100],
                  }}
                >
                  <Typography variant="body_sm">
                    ID: {garment.garment_id}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
          {additionalComponents.map((component) => {
            return component;
          })}
          {garments.length === 0 && showNoDataScreen && (
            <NoDataScreenCover showBackButton={false} />
          )}
        </Grid>
      </Box>
    </Grid>
  );
}
export default SingleSelectGarmentWindow;
