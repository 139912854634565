import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const SummaryDialog = ({ open, onClose, pipeline }) => {
  if (!pipeline) return null;

  // Helper function to summarize jobs
  const summarizeJobs = (jobs) =>
    jobs.map((job) => ({
      id: job.id,
      jobName: job.jobName,
      numUsers: job.num_users,
      labellingSet: job.labellingSet?.IMAGE_CLASS?.attributes
        ? job.labellingSet.IMAGE_CLASS.attributes.map((attr) => attr.name)
        : [],
    }));

  // Extract relevant data
  const jobSummaries = summarizeJobs(pipeline.jobs);

  // Gather users into a unique list and assign jobs they are part of
  const userJobMapping = pipeline.jobs.reduce((acc, job) => {
    job.users.forEach((user) => {
      if (!acc[user.id]) {
        acc[user.id] = { ...user, jobs: [] };
      }
      acc[user.id].jobs.push(job.jobName);
    });
    return acc;
  }, {});
  const uniqueUsers = Object.values(userJobMapping);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Pipeline Summary</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6" gutterBottom>
          General Information
        </Typography>
        <Typography variant="body1">
          <strong>Pipeline Name:</strong> {pipeline.name}
        </Typography>
        <Typography variant="body1">
          <strong>Target Quantity:</strong> {pipeline.targetQuantity}
        </Typography>
        <Typography variant="body1">
          <strong>Pipeline ID:</strong> {pipeline.pipelineId}
        </Typography>
        <Typography variant="body1">
          <strong>Priority:</strong> {pipeline.priority}
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom>
          Jobs
        </Typography>
        <List>
          {jobSummaries.map((job) => (
            <React.Fragment key={job.id}>
              <ListItem>
                <ListItemText
                  primary={job.jobName}
                  secondary={
                    <>
                      <Typography variant="body2">
                        <strong>Number of Users:</strong> {job.numUsers}
                      </Typography>
                      {job.labellingSet.length > 0 && (
                        <Typography variant="body2">
                          <strong>Labelling Set:</strong>{" "}
                          {job.labellingSet.join(", ")}
                        </Typography>
                      )}
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>

        <Typography variant="h6" gutterBottom>
          Active Users and Assigned Jobs
        </Typography>
        <List>
          {uniqueUsers.map((user) => (
            <React.Fragment key={user.id}>
              <ListItem>
                <ListItemText
                  primary={user.name}
                  secondary={
                    <>
                      <Typography variant="body2">
                        <strong>Bridge User:</strong> {user.bridge_user}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Assigned Jobs:</strong> {user.jobs.join(", ")}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SummaryDialog;
