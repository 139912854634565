import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchableMultiSelect from "../../../../components/forms/Dropdowns/SearchableMultiSelect";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import useSize from "../../../../hooks/useSize";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
const PlotlyRenderers = createPlotlyRenderers(Plot);

const AggregationLinkingUserProgressPivotTable = forwardRef(({fillPage=false, sx={}, width=null, height=null}, ref) => {
  const [aggregationLinkingUserProgressPivotData, setAggregationLinkingUserProgressPivotData] = useState([]);
  const [pivotState, setPivotState] = useState({});
  const { palette, typography } = useTheme();
  const [date, setDate] = React.useState([dayjs().subtract(1, "day"), dayjs()]);
  const [dataLoading, setDataLoading] = useState(false);
  const windowSize = useSize();
  const chartWidth = fillPage ? windowSize[0] : (width ?? 0.5*windowSize[0])
  const chartHeight = fillPage ? 0.3*windowSize[1] : (height ?? 0.25*windowSize[1])
  const itemsPerPage = 1000

  const refreshData = async () => {
    setAggregationLinkingUserProgressPivotData([]);
    setDataLoading(true);
    await fetchData()
  };

  useImperativeHandle(ref, () => {
    return {
      refreshData
    }
  })

  useEffect(() => {
    const pivotState = {
      data: aggregationLinkingUserProgressPivotData,
      rows: ['bridge_user', 'is_positive'],
      cols: ['proposal_hour'],
      aggregatorName: 'Integer Sum',
      vals: ['garments'],
      rendererName: 'Table',
      //sorters: {
      //    Meal: sortAs(['Lunch', 'Dinner']),
      //    'Day of Week': sortAs([
      //        'Thursday',
      //        'Friday',
      //        'Saturday',
      //        'Sunday',
      //    ]),
      //},
      plotlyOptions: {width: chartWidth, height: chartHeight}
      //plotlyConfig: {},
      //tableOptions: {
      //    clickCallback: function(e, value, filters, pivotData) {
      //        var names = [];
      //        pivotData.forEachMatchingRecord(filters, function(
      //            record
      //        ) {
      //            names.push(record.Meal);
      //        });
      //        alert(names.join('\n'));
      //    },
      //},
    }
    console.log(`aggregationLinkingUserProgressPivotData: ${JSON.stringify(aggregationLinkingUserProgressPivotData)}`)
    setPivotState(pivotState)
  }, [aggregationLinkingUserProgressPivotData])

  const fetchData = async (page = 1) => {
    setDataLoading(true);
    try {
      let headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/analytics/pivots/aggregation/linking-user-progress`,
        {
          startDate: date[0].toISOString(),
          endDate: date[1].toISOString(),
          page: page,
          limit: itemsPerPage,
        },
        headers
      );

      setAggregationLinkingUserProgressPivotData((prev) => [...prev, ...response.data.data]);

      // Check if there are more items to fetch
      if (response.data.data.length === itemsPerPage) {
        await fetchData(page + 1); // Fetch next page
      } else {
        setDataLoading(false);
      }

      //return aggregationLinkingUserProgressPivotData;
    } catch (error) {
      setDataLoading(false);
      console.error(`Error fetching proposals for Aggregation Linking User Progress:`, error);
      showAlert(error);
      //return []; // Return whatever was fetched before the error
    }
  };

  useEffect(() => {
    refreshData()
  }, [date, itemsPerPage])

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant={fillPage ? "h2" : "h4"} sx={{
          p: 1, pt: 0
        }} color="primary.800">
          Aggregation Linking User Progress Pivot Table
        </Typography>
        <Box
          sx={{
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{ start: "Start", end: "End" }}
              onChange={(newValue) => setDate(newValue)}
              value={date}
            />
          </LocalizationProvider>

          {!ref && 
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={refreshData}>
              Refresh
            </Button>
          </Box>}
          
        </Box>

        <Box
          sx={{
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            ...sx
          }}
        >
          <PivotTableUI
            onChange={setPivotState}
            {...pivotState}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
})

export default AggregationLinkingUserProgressPivotTable;
