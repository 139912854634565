import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import StyledDataGrid from "../ui/dataViews/StyledDataGrid";
import Delete from "@mui/icons-material/Delete";

function UserTable({ users, setUsers }) {
  const columns = [
    { field: "name", headerName: "User", width: 150 },
    {
      field: "confidence",
      headerName: "Confidence",
      width: 150,
      valueGetter: () => "0.8",
    },
    {
      field: "currentWorkload",
      headerName: "Current Workload",
      width: 150,
      valueGetter: () => "N/A",
    },
    {
      field: "action",
      headerName: "Remove",
      width: 150,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          color="primary"
          onClick={() => {
            // Add your delete logic here
            console.log("Delete user with ID: ", params.row.id);
            // Update the users state to reflect the changes
            const newUsers = users.filter((user) => user.id !== params.row.id);
            setUsers(newUsers);
          }}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <StyledDataGrid rows={users} columns={columns} />
    </Box>
  );
}

export default UserTable;
