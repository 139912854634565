import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@emotion/react";
import SelectableList from "../../../components/features/GarmentAttribute/SelectableList";
import ImageWindow from "../../../components/layout/Interactive/ImageWindow";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import MassImageApprovalsFrame from "../../../components/layout/Interactive/MassImageApprovalsFrame";
import { jobQaMap, jobTypes } from "../../../config/jobTypes";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import Slider from "@mui/material/Slider";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import CustomModal from "../../../components/common/userFeedback/CustomModal";
import SeedGarment from "../../../components/ui/cards/SeedGarment";
import { StyledButton } from "../../../components/ui/buttons/StyledButton";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import DatasetDropdown from "../../../components/forms/Dropdowns/DatasetDropdown";
import PineconeIndexDropdown from "../../../components/forms/Dropdowns/PineconeIndexDropdown";
import { logPerformancePoint } from "../../../utils/sharedFunctions";

export default function AggregationGroupingTool() {
  const [loading, setLoading] = useState(false);
  const [garments, setGarments] = useState([]);
  const [rejectedTags, setRejectedTags] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingLargestGroup, setLoadingLargestGroup] = useState(false);
  const [loadingGarments, setLoadingGarments] = useState(false);
  const [presentedTimeStamp, setPresentedTimeStamp] = useState(null);
  const [groupRepresentativeChanged, setGroupRepresentativeChanged] =
    useState(false);

  const theme = useTheme();
  const { trackEvent } = useAmpltiudeEventTrackingContext();

  const [selectedDataset, setSelectedDataset] = useState(null);
  const [openSearchIndex, setOpenSearchIndex] = useState("vetements");

  const [datasets, setDatasets] = useState([]);
  const [openSearchIndexes, setOpenSearchIndexes] = useState([]);
  const [associatedGarmentsCount, setAssociatedGarmentsCount] = useState(0);
  const [rejectedGarmentsCount, setRejectedGarmentsCount] = useState(0);
  const [limit, setLimit] = useState(15);
  const [largestGroup, setLargestGroup] = useState({
    garment_count: 0,
    group_uuid: null,
  });
  const [newGroup, setNewGroup] = useState({
    garment_count: 0,
    group_uuid: null,
  });
  const [groupRepresentative, setGroupRepresentative] = useState(null);
  const [selectedGroupRepresentative, setSelectedGroupRepresentative] =
    useState(null);
  const [groupRepresentativeModalOpen, setGroupRepresentativeModalOpen] =
    useState(false);
  const [numberOfColumns, setNumberOfColumns] = useState(4); // Default to 3 columns
  const [totalGarmentsSeen, setTotalGarmentsSeen] = useState(0);

  useEffect(() => {
    setLimit(numberOfColumns * 3);
  }, [numberOfColumns]);

  async function fetchIndexes() {
    const headers = await getHeaders();

    await axios
      .get(`${BASE_URL}tools/aggregation/list-indexes`, headers)
      .then((res) => {
        console.log(res.data.data);
        setOpenSearchIndexes(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
  }
  async function fetchDatasets() {
    const headers = await getHeaders();
    try {
      const res = await axios.get(
        `${BASE_URL}tools/admin/datasets/listall`,
        headers
      );
      setDatasets(res.data.data.filter(x => !x.is_archived));
    } catch (error) {
      console.log(error);
      showAlert(error);
    }
  }
  async function fetchLargestGroup() {
    const headers = await getHeaders();
    setLoadingLargestGroup(true);
    try {
      const t0 = logPerformancePoint(0);
      const res = await axios.post(
        `${BASE_URL}tools/aggregation/fetch-largest-group`,
        { datasetID: selectedDataset?.id, index: openSearchIndex },
        headers
      );
      const t1 = logPerformancePoint(1, t0);
      console.log(res.data.data);
      setLoadingLargestGroup(false);
      setLargestGroup(res.data.data);
    } catch (error) {
      console.log(error);
      setLoadingLargestGroup(false);
      showAlert(error);
    }
  }
  async function fetchGarments() {
    const headers = await getHeaders();
    if (!largestGroup?.group_uuid) {
      return;
    }
    if (loadingGarments) {
      return;
    }
    setLoadingGarments(true);

    try {
      const res = await axios.post(
        `${BASE_URL}tools/summon/next-item/qa/aggregation`,
        {
          group_uuid: largestGroup.group_uuid,
          offset: (page - 1) * limit,
          limit: limit,
          new_group_uuid: newGroup.group_uuid,
        },
        headers
      );
      setGarments(res.data.data);
      setRejectedTags([]);
      setTotalGarmentsSeen(
        (prev) =>
          prev +
          (garments.length)
      );
      setPresentedTimeStamp(getCurrentTimestamp());

      if (
        res.data.data.length === 0 &&
        !loadingGarments &&
        !loadingLargestGroup &&
        page > Math.ceil(largestGroup?.garment_count / limit)
      ) {
        handleResetNewGroup();
        setGarments([]);
        setRejectedTags([])
        setGroupRepresentative(null);
        setSelectedGroupRepresentative(null);
        fetchLargestGroup();
      }
      setLoadingGarments(false);
    } catch (error) {
      setLoadingGarments(false);
      showAlert(error);
    }
  }
  const handleNextPage = async () => {
    await handleSubmit();
    setPage(page + 1);
  };

  const handleSubmit = async () => {
    setLoadingGarments(true);
    let combinedArray = rejectedTags
      .map((tag) => {
        const garment = garments.find((g) => g.garment_id === tag.id);
        if (garment) {
          return {
            ...tag,
            ...garment,
            presented_timestamp: presentedTimeStamp,
          }; // Merge the objects if there's a match
        }
        return null;
      })
      .filter((x) => x);
    
    if (groupRepresentativeChanged && groupRepresentative) {
      if (combinedArray.filter(item => item.garment_id === groupRepresentative.garment_id).length > 0)
      {
        combinedArray = combinedArray.map(item =>
          item.garment_id === groupRepresentative.garment_id ? {
            ...groupRepresentative,
            is_group_representative: true,
            presented_timestamp: presentedTimeStamp,
          } : item
        );
      } else {
        combinedArray.push({
          ...groupRepresentative,
          is_group_representative: true,
          presented_timestamp: presentedTimeStamp,
        });
      }
    }

    const params = {
      selected_garments: combinedArray,
      group_uuid: largestGroup.group_uuid,
      new_group_uuid: newGroup.group_uuid,
      starting_group_size: largestGroup.garment_count,
    };
    setAssociatedGarmentsCount((prev) => prev + combinedArray.length);
    setRejectedGarmentsCount(
      (prev) => prev + (garments.length - combinedArray.length)
    );
    const headers = await getHeaders();
    const res = await axios
      .post(`${BASE_URL}tools/classify/qa/aggregation`, params, headers)
      .then(() => {
        setNewGroup({
          ...newGroup,
          garment_count: newGroup.garment_count + combinedArray.length,
        });
        setGroupRepresentativeChanged(false);
        console.log(`combinedArray: ${JSON.stringify(combinedArray)}`)
        trackEvent("AggregationQA", {
          index: openSearchIndex,
          datasetId: selectedDataset?.id,
          garmentId: groupRepresentative["garment_id"],
          aggregations: newGroup.garment_count,
          
          selectedGarmentIds: combinedArray.map(x => x.garment_id),
          rejectedGarmentIds: garments.map(x => x.garment_id).filter(x => !Object.keys(combinedArray).includes(x)),
          garmentsSelected: combinedArray.length,
          garmentsRejected: garments.length - combinedArray.length,
          garmentsSeen: totalGarmentsSeen,
          jobId: 17,
          jobName: "grouping",
          toolType: "GROUPING",
          toolName: "AggregationGroupingTool",
          qa: true,
          skipped: combinedArray.length === 0, //"Skipped" is no selected garments
        });
      })

      .catch((err) => {
        showAlert(err);
      });
      setLoadingGarments(false);
  };

  const handleResetNewGroup = () => {
    const newUUID = uuidv4();
    setNewGroup({
      group_uuid: newUUID,
      garment_count: 0,
    });
    setRejectedTags([]);
    setGarments([]);
    setAssociatedGarmentsCount(0);
    setRejectedGarmentsCount(0);
    setTotalGarmentsSeen(0);
  };

  useEffect(() => {
    if (openSearchIndex) {
      setGarments([]);
      setRejectedTags([]);
      setGroupRepresentative(null);
      setSelectedGroupRepresentative(null);
      fetchLargestGroup();
    }
  }, [selectedDataset, openSearchIndex]);

  useEffect(() => {
    setPage(1);
    handleResetNewGroup();
  }, [largestGroup]);

  useEffect(() => {
    console.log(`fetchGarments`)
    fetchGarments();
  }, [page, largestGroup, limit]);

  useEffect(() => {
    fetchDatasets();
    fetchIndexes();
    if (!newGroup.group_uuid) {
      handleResetNewGroup();
    }
  }, []);

  const handleControlClick = (garmentId) => {
    // Find Garment
    const [tempGarment] = garments.filter((g) => g.garment_id === garmentId);
    setSelectedGroupRepresentative({
      ...tempGarment,
      proposed_timestamp: getCurrentTimestamp(),
    });
    setGroupRepresentativeModalOpen(true);
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      }}
    >
      <CustomModal
        showModal={groupRepresentativeModalOpen}
        title={"Set Group Representative"}
        body={
          " Are you sure you would like to chnage the group representative, this cannot be undone."
        }
        onDismissTitle="Return"
        onConfirmTitle="Confirm"
        onConfirm={() => {
          setGroupRepresentative(selectedGroupRepresentative);
          setGroupRepresentativeModalOpen(false);
          setSelectedGroupRepresentative(null);
          setGroupRepresentativeChanged(true);

          trackEvent("AggregationQASetGroupRepresentative", {
            index: openSearchIndex,
            datasetId: selectedDataset?.id,
            garmentId: selectedGroupRepresentative["garment_id"],
            proposedTimestamp:
              selectedGroupRepresentative["proposed_timestamp"],
            jobId: 17,
            jobName: "grouping",
            toolType: "GROUPING",
            toolName: "AggregationGroupingTool",
            qa: true,
          });
        }}
        onDismiss={() => {
          setSelectedGroupRepresentative(null);
          setGroupRepresentativeModalOpen(false);
        }}
      />
      <LoadingOverlay
        open={loadingGarments || loadingLargestGroup}
        text={
          loadingGarments
            ? "Loading Garments"
            : loadingLargestGroup
            ? "Loading Largest Group"
            : ""
        }
      />
      <Grid
        container
        width="100%"
        spacing={2}
        sx={{
          alignItems: "flex-end"
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Typography variant="h2">Grouping Tool</Typography>
          <StyledButton
            variant="contained"
            color="primary"
            sx={{ ml: 2, color: theme.palette.primary[100] }}
            disabled={
              loadingGarments || loadingLargestGroup || !groupRepresentative
            }
            onClick={handleNextPage}
          >
            Next Page
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            pb: 2,
            mt: -2
          }}
        >
          <DatasetDropdown
            dataset={selectedDataset}
            setDataset={setSelectedDataset}
            sx={{ mr: 2 }}
          />
          <PineconeIndexDropdown
            index={openSearchIndex}
            setIndex={setOpenSearchIndex}
          />
          <Select
            value={numberOfColumns}
            onChange={(e) => setNumberOfColumns(e.target.value)}
            displayEmpty
            sx={{ ml: 2 }}
          >
            <MenuItem value={4}>4 Column</MenuItem>
            <MenuItem value={5}>5 Columns</MenuItem>
            <MenuItem value={6}>6 Columns</MenuItem>
            <MenuItem value={7}>7 Columns</MenuItem>
            <MenuItem value={8}>8 Columns</MenuItem>
          </Select>
          <Divider
            sx={{
              m: 1,
            }}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{
          m: 1,
          mt: 0
        }}
      />
      {openSearchIndex ? (
        (garments.length === 0) & !loading ? (
          <NoDataScreenCover showBackButton={false} />
        ) : (
          <Grid container>
            <Grid xs={9}>
              <MassImageApprovalsFrame
                garments={garments}
                rejectedTags={rejectedTags}
                setRejectedTags={setRejectedTags}
                disabled={!groupRepresentative}
                disabledClickMessage={"Please set a group representative (CTRL + click)."}
                handleControlClick={handleControlClick}
                reject={false}
                numberOfColumns={numberOfColumns}
              />
            </Grid>
            <Grid
              xs={3}
              sx={{
                mt: 1,
              }}
            >
              <Typography variant="h3">Group Representative</Typography>

              <Box
                sx={{
                  border: `1px solid ${theme.palette.primary[400]}`,
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <SeedGarment garment={groupRepresentative} />
              </Box>
            </Grid>
          </Grid>
        )
      ) : (
        <TakeActionScreenCover />
      )}

      <Divider
        sx={{
          m: 1,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          Page: {page} /{" "}
          {largestGroup?.garment_count <= 0
            ? 0
            : Math.ceil(largestGroup?.garment_count / limit)}
        </Typography>
        <Typography variant="h4" color="success.main">
          {associatedGarmentsCount}
        </Typography>
        <Typography variant="h4" color="error.main">
          {rejectedGarmentsCount}
        </Typography>
        <Typography>Group Size: {largestGroup?.garment_count}</Typography>
        <Typography>UUID: {largestGroup?.group_uuid}</Typography>
      </Box>
    </Box>
  );
}
