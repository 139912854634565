import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Typography, IconButton, useTheme, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const DraggableList = ({
  onEditClick,
  items = [],
  setItems,
  menuItems = [],
  styleMap,
  onAddJob, // Function to add a job
  updateJob, // Function to update a job
  onRemoveJob, // Function to remove a job
}) => {
  const { palette } = useTheme();
  console.log("items", items);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside any list
    if (!destination) {
      return;
    }

    if (source.droppableId === "menu" && destination.droppableId === "items") {
      // Adding a job from the menu to the items list
      const draggedItem = menuItems.find(
        (item) => item.id === result.draggableId
      );
      if (draggedItem) {
        onAddJob(draggedItem.id); // Add job via JobManager
      }
    } else if (
      source.droppableId === "items" &&
      destination.droppableId === "items"
    ) {
      // Reordering jobs in the items list
      const reorderedItems = Array.from(items);
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);
      setItems(reorderedItems); // Update state with reordered list
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
        {/* Menu Items List */}
        <Droppable droppableId="menu">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                backgroundColor: palette.grey[200],
                padding: 2,
                borderRadius: 2,
                width: "150px",
                overflowY: "auto",
              }}
            >
              {menuItems.map((item, index) => {
                console.log(item.mapping_value);
                console.log(styleMap);
                console.log(styleMap[item.mapping_value]);
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Tooltip title={item.name} placement="right">
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            userSelect: "none",
                            padding: 1,
                            margin: "0 0 8px 0",
                            backgroundColor: "white",
                            borderRadius: 1,
                            display: "flex",
                            alignItems: "center",
                            opacity: 1,
                            ...(styleMap[item.mapping_value] || {}),
                          }}
                        >
                          {item.icon}
                        </Box>
                      </Tooltip>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        {/* Jobs List */}
        <Droppable droppableId="items">
          {(provided) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                backgroundColor: palette.primary[300],
                padding: 2,
                borderRadius: 2,
                flexGrow: 1,
                minHeight: "500px",
              }}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        userSelect: "none",
                        padding: 1,
                        marginBottom: 1,
                        backgroundColor: "white",
                        borderRadius: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        ...(styleMap[item.mapping_value] || {}),
                      }}
                    >
                      <DragIndicatorIcon />
                      <Typography>{item.name}</Typography>
                      <Box>
                        <IconButton onClick={() => onEditClick(item)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => onRemoveJob(item.id)} // Remove job via JobManager
                        >
                          <DeleteIcon sx={{ color: "red" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </Box>
    </DragDropContext>
  );
};

export default DraggableList;
