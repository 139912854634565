import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useJobConfigurationContext } from "../../../../contexts/JobConfigurationContext";

function ConfigurationDialogBase({ children }) {
  const handleClose = () => {
    setActiveJob(null);
  };
  const { activeJob, setActiveJob, handleSave, error } =
    useJobConfigurationContext();

  return (
    <Dialog open={!!activeJob}>
      <DialogTitle variant="h3">Configure {activeJob?.name}</DialogTitle>
      <DialogContent
        sx={{
          width: "35vw",
          minHeight: "50vh",
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
}

export default ConfigurationDialogBase;
