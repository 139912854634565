import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import UserMultiSelect from "../../../../forms/Dropdowns/UserMultiSelect";
import UserTable from "../../../../layout/BasicUserTable";

const UserSection = ({
  selectedUsers,
  setSelectedUsers,
  setUnsavedChanges,
}) => {
  return (
    <Box>
      <Typography variant="h4">Assign Users</Typography>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <UserMultiSelect setSelectedUsers={setSelectedUsers} />
        <UserTable users={selectedUsers} setUsers={setSelectedUsers} />
      </Box>
    </Box>
  );
};

export default UserSection;
