import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const navLinks = [
  {
    title: "Home",
    path: "/",
    icon: <HomeOutlinedIcon />
  },
  {
    title: "Pipelines",
    path: "/admin/pipelines",
  },
  {
    title: "Dataset Management",
    path: "/admin/dataset-management",
  },
  {
    title: "Image Tag Management",
    path: "/admin/attribute-management/image_class",
  },
  {
    title: "Type Management",
    path: "/admin/attribute-management/type",
  },
  {
    title: "Style Management",
    path: "/admin/attribute-management/style",
  },
  {
    title: "User Management",
    path: "/admin/users",
  },
  {
    title: "Grouping",
    path: "/jobs/grouping",
  },
  {
    title: "Cataloguing Groups",
    path: "/admin/cataloguing/groups",
  },
  {
    title: "Cataloguing Slipstream",
    path: "/admin/cataloguing/slipstream",
  },
  {
    title: "Pipeline Jobs Analytics",
    path: "/admin/pipeline-jobs-analytics",
  },
  {
    title: "Aggregation Analytics",
    path: "/admin/analytics/aggregation",
  },
  {
    title: "Cataloguing Analytics",
    path: "/admin/analytics/cataloguing",
  },
  {
    title: "Image Tagging Analytics",
    path: "/admin/analytics/tagging/image-tagging",
  },
];
export { navLinks };
