import React from "react";
import {
  JobConfigurationProvider,
  useJobConfigurationContext,
} from "../../../../contexts/JobConfigurationContext";
import QAConfigurationDialog from "./QAConfigurationDialog";
import JobConfigurationDialog from "./JobConfigurationDialog";

function DialogMapper({
  activeJob,
  setActiveJob,
  setUnsavedChanges,
  unsavedChanges,
  handleJobUpdate,
}) {
  return (
    <JobConfigurationProvider
      activeJob={activeJob}
      setActiveJob={setActiveJob}
      setUnsavedChanges={setUnsavedChanges}
      unsavedChanges={unsavedChanges}
      handleJobUpdate={handleJobUpdate}
    >
      <DialogRenderer />
    </JobConfigurationProvider>
  );
}

function DialogRenderer() {
  const { activeJob } = useJobConfigurationContext();
  if (!activeJob) {
    return null;
  }

  if (activeJob.mapping_value === "qa") {
    return <QAConfigurationDialog />;
  } else if (activeJob.mapping_value === "job") {
    return <JobConfigurationDialog />;
  }
  return null;
}

export default DialogMapper;
