import React, { useState } from "react";
import { Container, Box } from "@mui/material";
import JobConfigurationDialog from "./Dialogues/JobConfigurationDialog";
import { usePipelineContext } from "../../../contexts/PipelineContext";
import { jobTypes } from "../../../config/jobTypes";
import { createJob } from "../../../utils/jobManager";
import DraggableList from "../../layout/Interactive/DraggableList";
import { defaultJobs } from "../../../config/pipelineConfig";
import DialogMapper from "./Dialogues/DialogueMapper";

function JobConfigurationSection() {
  const { orderedJobs, addJob, updateJob, removeJob } = usePipelineContext();
  const [activeJob, setActiveJob] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleJobUpdate = (updatedJob) => {
    console.log("updatedJob", updatedJob);
    updateJob(updatedJob.id, updatedJob);
    setUnsavedChanges(true);
    setActiveJob(null);
  };

  const handleJobRemove = (jobId) => {
    removeJob(jobId);
    setUnsavedChanges(true);
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          flex: 1,
        }}
      >
        <DraggableList
          items={orderedJobs}
          onAddJob={(job) => {
            addJob(job);
            setUnsavedChanges(true);
          }}
          onEditClick={(item) => setActiveJob(item)}
          onRemoveJob={handleJobRemove}
          styleMap={{
            job: { backgroundColor: "#86d3db" },
            qa: { backgroundColor: "#89db86" },
            function: { backgroundColor: "#dba786" },
          }}
          menuItems={defaultJobs}
        />
        {activeJob && (
          <DialogMapper
            activeJob={activeJob}
            setActiveJob={setActiveJob}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            handleJobUpdate={handleJobUpdate}
          />
        )}
      </Box>
    </Container>
  );
}

export default JobConfigurationSection;
