import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { Dialog, CircularProgress } from "@mui/material";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import { useParams } from "react-router-dom";
import { ToolProvider, useToolContext } from "../../../../contexts/ToolContext";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
import { PaginationButtonSet } from "../../../../components/ui/buttons/TableButtons";
import GarmentCard from "../../../../components/ui/cards/GarmentCard";
import { APP_BAR_HEIGHT } from "../../../../components/layout/AppBar/AppBarComponent";
import SearchableMultiSelectV2 from "../../../../components/forms/Dropdowns/SearchableMultiSelectV2";
import StatusMultiSelect from "../../../../components/forms/Dropdowns/StatusMultiSelect";
import OrderByDropdown from "../../../../components/forms/Dropdowns/OrderBy";
import LoadingOverlay from "../../../../components/common/userFeedback/LoadingOverlay";

function PipelineJobsDataview() {
  const { jobDetails, pipelineJobId, jobDataLoaded, toolType } =
    useToolContext();
  const [rawProposals, setRawProposals] = useState([]); // Raw data from API
  const [toolInfo, setToolInfo] = useState(null); // Tool Info from middleware
  const [typeMapping, setTypeMapping] = useState("BRAND");
  const [page, setPage] = useState(1);
  const { palette, typography } = useTheme();
  const [dataLoading, setDataLoading] = useState(true);
  const [dataExhausted, setDataExhausted] = useState(false);
  const [fixProposals, setFixProposals] = useState(false);
  const [orderBy, setOrderBy] = useState("DEFAULT");
  const [numberOfColumns, setNumberOfColumns] = useState(
    toolType && toolType === "IMAGE_TAGGING_QA" ? 8 : 4
  ); // Default to 3 columns
  const [limit, setLimit] = useState(12);
  console.log(`toolTypetoolType: ${toolType}`);
  //Filters
  const [users, setUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [proposalStatuses, setProposalStatuses] = useState([]);

  const resetData = () => {
    console.log();
  };

  const loadData = async () => {
    await fetchToolData();
    await fetchData();
  };

  useEffect(() => {
    setLimit(numberOfColumns * 3);
  }, [numberOfColumns]);

  const fetchToolData = async () => {
    setDataLoading(true);
    setToolInfo(null);

    const params = {
      toolType: toolType,
    };
    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/jobs/tool-info`,
        params,
        headers
      );

      const words = response.data.data.name.replace("_", " ").split(" ");
      response.data.data.display_name = words
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
      if (response.data.data.display_name === "Image Class") {
        response.data.data.display_name = "Image Tag";
      }

      setTypeMapping(response.data.data.name.toUpperCase());
      setToolInfo(response.data.data);
      setDataLoading(false);

      console.log(
        `SUCCESS toolInfotoolInfotoolInfo: ${JSON.stringify(
          response.data.data
        )}`
      );
    } catch (error) {
      console.error(`ERROR: ${error}`);
      showAlert(error); // Automatically extracts the message and shows the alert
    }
  };

  const fetchData = async () => {
    setDataLoading(true);
    setRawProposals([]);

    const params = {
      page: page,
      limit: limit,
      pipelineJobId: pipelineJobId,
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      filters: {
        USERS: users.map((u) => u.id),
        LABELS: labels.map((l) => l.id),
        PROPOSAL_STATUSES: proposalStatuses.map((ps) => ps.name),
      },
      orderBy: orderBy,
    };
    try {
      const headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/jobs/pipeline-job-dataview`,
        params,
        headers
      );

      setDataLoading(false);
      setRawProposals(response.data.data);

      if (response.data.data.length < limit) {
        setDataExhausted(true);
      } else {
        setDataExhausted(false);
      }
      console.log(`SUCCESS: ${JSON.stringify(response.data.data)}`);
    } catch (error) {
      console.error(`ERROR: ${error}`);
      showAlert(error); // Automatically extracts the message and shows the alert
    }
  };
  useEffect(() => {
    setPage(1);
  }, [users, labels, proposalStatuses, orderBy]);
  useEffect(() => {
    resetData();
    if (jobDataLoaded) {
      loadData();
    }
  }, [
    jobDataLoaded,
    pipelineJobId,
    page,
    users,
    labels,
    proposalStatuses,
    orderBy,
    toolType,
    typeMapping,
    numberOfColumns,
  ]);

  const handleFixProposalsChange = (event) => {
    setFixProposals(event.target.checked);
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LoadingOverlay open={dataLoading} />

      <Box
        sx={{
          p: 1,
        }}
      >
        <Typography variant="h2">Pipeline Job Data View</Typography>
        <Grid
          container
          sx={{
            alignItems: "center",
          }}
          spacing={2}
        >
          <Grid item container xs={12} sm={8} spacing={0}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <SearchableMultiSelectV2
                typeMapping={"USER"}
                selected={users}
                onSelectedChange={setUsers}
                label={"Select Users"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              {toolInfo && (
                <SearchableMultiSelectV2
                  typeMapping={toolInfo.name.toUpperCase()}
                  orderBy={"image_class"}
                  selected={labels}
                  onSelectedChange={setLabels}
                  label={`Select ${toolInfo.display_name} Label`}
                />
              )}
              {!toolInfo && (
                <SearchableMultiSelectV2
                  typeMapping={"Brand".toUpperCase()}
                  orderBy={"image_class"}
                  selected={labels}
                  onSelectedChange={setLabels}
                  label={`Select Brand Label`}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <StatusMultiSelect
                selected={proposalStatuses}
                onSelectedChange={setProposalStatuses}
                label={"Select Proposal Status"}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={4} spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Select
                value={numberOfColumns}
                onChange={(e) => setNumberOfColumns(e.target.value)}
                displayEmpty
                sx={{ mt: 1.25 }}
              >
                <MenuItem value={4}>4 Column</MenuItem>
                <MenuItem value={5}>5 Columns</MenuItem>
                <MenuItem value={6}>6 Columns</MenuItem>
                <MenuItem value={7}>7 Columns</MenuItem>
                <MenuItem value={8}>8 Columns</MenuItem>
                <MenuItem value={9}>9 Columns</MenuItem>
                <MenuItem value={10}>10 Columns</MenuItem>
                <MenuItem value={11}>11 Columns</MenuItem>
                <MenuItem value={12}>12 Columns</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <OrderByDropdown setOrderBy={setOrderBy} orderBy={orderBy} />
            </Grid>

            <Grid item xs={12} sm={6} md={3} lg={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fixProposals}
                    onChange={handleFixProposalsChange}
                    color="primary" // This is the color of the checkbox
                  />
                }
                label={<Typography variant="h8">Fix Proposals</Typography>}
                sx={{
                  "& .MuiTypography-root": {
                    // Style the label
                    fontSize: "1rem", // Adjust font size as needed
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flex: 1, // Take up remaining space
          overflowY: "auto", // Make the container scrollable
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: palette.primary[300],
            width: "100%",
            p: 1,
            height: "100%",
          }}
          rowSpacing={0}
        >
          {rawProposals.map((garmentData) => (
            <Grid item xs={12 / numberOfColumns} key={garmentData.id}>
              {/* Ensure each item has a unique key */}
              <GarmentCard
                imageNum={garmentData.image_num}
                garmentData={garmentData}
                fixProposals={fixProposals}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          height: 100,
        }}
      >
        <PaginationButtonSet
          setPage={setPage}
          page={page}
          disabled={dataLoading}
          nextPageDisabled={dataExhausted}
          previousPageDisabled={page === 1}
        />
      </Box>
    </Box>
  );
}
function WrappedPipelineJobsDataview() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <PipelineJobsDataview />
    </ToolProvider>
  );
}

export default WrappedPipelineJobsDataview;
