import { Box, Typography } from "@mui/material";
import {
  getHexColorsFromWords,
  colorIsCloserToWhiteThanBlack,
} from "./colorUtils";
import { API_TIMEOUT_SECONDS } from "../config/apiConfig";
// Helper function to format the date

export function getCurrentLine(error=null) {
  let err = new Error();
  if (error)
  {
    err = error
  }
  const stack = err.stack || "";
  const stackLines = stack.split("\n");
  console.log(`stackLines: ${JSON.stringify(stackLines)}`)
  
  // The line of interest is usually the third line (0: Error, 1: function, 2: caller)
  return stackLines[2] || "No stack information available";
}


export function logPerformancePoint(pointNum=0, prevPoint=null, extraLogs="") {
  const callerName = getErrorFunctionLine(null,null,3);
  let t
  if (pointNum === 0)
  {
    t = performance.now();
    console.log(`\x1b[32m[${callerName}] Performance Point 0: ${t} ms\x1b[0m`);
    if (extraLogs && extraLogs !== "")
    {
      console.log(`\x1b[32m   ${extraLogs}\x1b[0m`)
    }
  } else {
    t = performance.now();
    console.log(`\x1b[32m[${callerName}]${((t - prevPoint).toFixed(2)/1000) >= API_TIMEOUT_SECONDS ? `\x1b[31m[WARNING - MAY CAUSE API TIMEOUT (>30s)]\x1b[32m` : ``} Performance Point ${pointNum}: ${t} ms. Time from Point ${pointNum - 1} to Point ${pointNum}: ${(t - prevPoint).toFixed(2)} ms (${(t - prevPoint).toFixed(2)/1000} s)\x1b[0m`);
    if (extraLogs && extraLogs !== "")
    {
      console.log(`\x1b[32m   ${extraLogs}\x1b[0m`)
    }
  }

  return t
}

function formatDate(date, format) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Replace format placeholders
  return format
      .replace('YYYY', year)
      .replace('MM', month)
      .replace('DD', day)
      .replace('HH', hours)
      .replace('mm', minutes)
      .replace('ss', seconds);
}

export const sortByProperty = (array, property, order = 'asc') => {
    return array.sort((a, b) => {
        if (order === 'asc') {
            return a[property] > b[property] ? 1 : -1;
        } else if (order === 'desc') {
            return a[property] < b[property] ? 1 : -1;
        }
        return 0;
    });
}

export const getErrorFunctionLine = (error=null) => {
  // Create an error object to capture the stack trace
  if (!error)
  {
    error = new Error();
  }
  const stack = error.stack;
  const stackLines = stack.split('\n');
  
  // The second line in the stack trace represents the function that called `innerFunction`]
  const callerLine = stackLines[2];

  // Extract the function name from the stack trace line
  const match = callerLine.match(/at (\S+)/);
  const callerName = match ? match[1] : 'Anonymous or global scope';
  return callerName;
}

export const filterByProperty = (array, property, operator = 'contains', value=null) => {
  const newArray = array.filter((x) => {
    switch (operator)
    {
      case "contains":
        return (value === "" || value === undefined || value === null) ? true : x[property] instanceof Date && !isNaN(x[property].getTime())
        ? formatDate(x[property], 'MM-DD-YYYY HH:mm').toLowerCase().includes(value.toString().toLowerCase()) 
        : (x[property] ?? "").toString().toLowerCase().includes(value.toString().toLowerCase()) 
      case "equals":
        return (value === "" || value === undefined || value === null) ? true : x[property] instanceof Date && !isNaN(x[property].getTime())
        ? formatDate(x[property], 'MM-DD-YYYY HH:mm') === value.toString()
        : (x[property] ?? "") === value.toString()
      case "starts with":
        return (value === "" || value === undefined || value === null) ? true : x[property] instanceof Date && !isNaN(x[property].getTime())
        ? formatDate(x[property], 'MM-DD-YYYY HH:mm').startsWith(value.toString())
        : (x[property] ?? "").toLowerCase().startsWith(value.toString().toLowerCase())
      case "ends with":
        return (value === "" || value === undefined || value === null) ? true : x[property] instanceof Date && !isNaN(x[property].getTime())
        ? formatDate(x[property], 'MM-DD-YYYY HH:mm').endsWith(value.toString().toLowerCase())
        : (x[property] ?? "").toLowerCase().endsWith(value.toString().toLowerCase())
      case "is empty":
        return (value === "" || value === undefined || value === null) ? true : x[property].length < 1
      case "is not empty":
        return (value === "" || value === undefined || value === null) ? true : x[property].length > 0
      case "is any of":
        return (value === "" || value === undefined || value === null) ? true : x[property] instanceof Date && !isNaN(x[property].getTime())
        ? x[property].some(item =>
          value.some(substring => formatDate(item, 'MM-DD-YYYY HH:mm').includes(substring.toString()))
        )
        : x[property].some(item =>
          value.some(substring => item.includes(substring.toString()))
        );
      default:
        return x
    }
  });
  return newArray
}

export const formatCurrency_GBP = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
};

export const defaultNoImageSrc = `src/assets/images/no-image.svg`;

export const addDefaultImg = (ev) => {
  ev.target.src = defaultNoImageSrc;
};

export const getArrayOfWords = (sentence) => {
  return sentence.split(/\s*,+\s*/);
};

export const capitalizeWords = (sentence, lower = false) => {
  return sentence
    ? (lower ? sentence.toLowerCase() : sentence).replace(
        /(?:^|\s|["'([{])+\S/g,
        (match) => match.toUpperCase()
      )
    : null;
};

export const getProgressColor = (progressString) => {
  switch (progressString.toLowerCase()) {
    //case "complete":
    //  return "purple";
    default:
      return "inherit";
  }
};

export const getGarmentColourComponentsFromSentence = (garmentColors) => {
  const garmentColorArray = getArrayOfWords(garmentColors);
  const hexColorArray = getHexColorsFromWords(garmentColorArray);
  console.log(
    `garmentColors: ${JSON.stringify(garmentColorArray)} ${JSON.stringify(
      hexColorArray
    )}`
  );

  const components = (
    <Box display="inline" sx={{ width: 200, height: 200 }}>
      {garmentColorArray ? (
        garmentColorArray.map((garmentColor, index) => (
          <Box sx={{ marginRight: 2 }} display="inline-block">
            <Box
              display="inline-block"
              sx={{
                mr: 1,
                mb: "-2px",
                borderRadius: "50%",
                backgroundColor:
                  hexColorArray[index] !== null &&
                  hexColorArray[index] !== undefined
                    ? `${hexColorArray[index]} !important`
                    : "",
                width: "1rem",
                height: "1rem",
              }}
            />
            <Typography
              display="inline"
              sx={{ textTransform: "capitalize !important" }}
            >
              {capitalizeWords(garmentColor.toLowerCase())}
            </Typography>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );

  return components;
};

export const getGarmentColourComponentsFromArrayOfWords = (
  garmentColorArray
) => {
  const hexColorArray = getHexColorsFromWords(garmentColorArray);
  console.log(
    `garmentColors: ${JSON.stringify(garmentColorArray)} ${JSON.stringify(
      hexColorArray
    )}`
  );

  const components = (
    <Box display="inline" sx={{ width: 200, height: 200 }}>
      {garmentColorArray ? (
        garmentColorArray.map((garmentColor, index) => (
          <Box sx={{ marginRight: 2 }} display="inline-block">
            <Box
              display="inline-block"
              sx={{
                mr: 1,
                mb: "-2px",
                borderRadius: "50%",
                backgroundColor:
                  hexColorArray[index] !== null &&
                  hexColorArray[index] !== undefined
                    ? `${hexColorArray[index]} !important`
                    : "",
                width: "1rem",
                height: "1rem",
              }}
            />
            <Typography
              display="inline"
              sx={{ textTransform: "capitalize !important" }}
            >
              {capitalizeWords(garmentColor.toLowerCase())}
            </Typography>
          </Box>
        ))
      ) : (
        <></>
      )}
    </Box>
  );

  return components;
};

export const getGarmentColourComponentsFromObject = (garmentColorsObject, showComposition=false) => {
  //const garmentColorNames = garmentColorsObject.map(x => x.name)
  //const garmentColorRGBs = garmentColorsObject.map(x => x.color)
  const components = (
    <Box display="inline" sx={{ width: 200, height: 200 }}>
      {garmentColorsObject ? (
        garmentColorsObject.map((garmentColorObject, index) => {
          let innerComponents = <></>;
          if (garmentColorObject) {
            const hasName = garmentColorObject.name !== null;
            const hasColor =
              garmentColorObject.red !== null &&
              garmentColorObject.green !== null &&
              garmentColorObject.blue !== null;
            let backgroundColor = hasColor
              ? `rgb(${garmentColorObject.red}, ${garmentColorObject.green}, ${garmentColorObject.blue})`
              : "";
            const hasMulticolorGarmentName =
              hasName &&
              (garmentColorObject.name.toLowerCase() === "multicolour" ||
                garmentColorObject.name.toLowerCase() === "multi colour" ||
                garmentColorObject.name.toLowerCase() === "multi-colour");

            if (hasMulticolorGarmentName) {
              backgroundColor = `linear-gradient(45deg,rgba(255, 0, 0, 1) 0%,rgba(255, 154, 0, 1) 10%,rgba(208, 222, 33, 1) 20%,rgba(79, 220, 74, 1) 30%,rgba(63, 218, 216, 1) 40%,rgba(47, 201, 226, 1) 50%,rgba(28, 127, 238, 1) 60%,rgba(95, 21, 242, 1) 70%,rgba(186, 12, 248, 1) 80%,rgba(251, 7, 217, 1) 90%,rgba(255, 0, 0, 1) 100%) !important`;
            }
            innerComponents = hasColor ? (
              <Box sx={{ marginRight: 2 }} display="inline-block">
                <Box
                  display="inline-block"
                  sx={{
                    mr: 1,
                    mb: "-2px",
                    borderRadius: "50%",
                    backgroundColor: !backgroundColor.startsWith(
                      "linear-gradient"
                    )
                      ? backgroundColor
                      : "",
                    background: backgroundColor.startsWith("linear-gradient")
                      ? backgroundColor
                      : "",
                    width: "1rem",
                    height: "1rem",
                    border: colorIsCloserToWhiteThanBlack(garmentColorObject)
                      ? `1px solid black`
                      : "",
                  }}
                />
                {hasName && (
                  <Typography
                    display="inline"
                    sx={{ textTransform: "capitalize !important" }}
                  >
                    {capitalizeWords(garmentColorObject.name.toLowerCase())} {showComposition && garmentColorObject.proportion && `(${(garmentColorObject.proportion * 100).toFixed(0)}%)`}
                  </Typography>
                )}
              </Box>
            ) : (
              <></>
            );
          } else {
            innerComponents = <></>;
          }
          return innerComponents;
        })
      ) : (
        <></>
      )}
    </Box>
  );

  return components;
};

export const getGarmentMaterialComponentsFromObject = (
  garmentMaterialObject
) => {
  let materialString = "";
  if (garmentMaterialObject) {
    const materialsLength = Object.keys(garmentMaterialObject).length;
    garmentMaterialObject.forEach((materialObj, index) => {
      if (materialObj.material == null) {
        return;
      }
      const formattedProportion = materialObj.proportion !== null ? (materialObj.proportion * 100).toFixed(0) : null;
      materialString =
        materialString +
        `${capitalizeWords(
          materialObj.material.replace("/", " / ")
        )}${formattedProportion ? ` ${formattedProportion}%` : ""}${
          materialsLength > 1 && index !== materialsLength - 1 ? `, ` : ""
        }`;
      console.log(
        `materialString: ${JSON.stringify(
          garmentMaterialObject
        )} ${JSON.stringify(materialString)} ${materialsLength}`
      );
    });
  }
  return materialString;
};

const getConditionComponent = (conditionLabel) => {
  switch (conditionLabel) {
    case "is_gently_used":
      return <Typography style={{}}>{conditionLabel}</Typography>;
    default:
      return <Typography style={{ color: "red" }}>{"N/A"}</Typography>;
  }
};

export function compareNamesAsc(a,b) {
  return compareStringsAsc(a,b,'name')
}

export function compareStringsAsc(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a['name'].toUpperCase();
  const bandB = b['name'].toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}