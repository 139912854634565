/**
 * @fileoverview PipelineContext is a context that provides the state and actions for the pipeline creation form.
 * It is used to manage the state of the pipeline creation form and to provide the state to the components that need it.
 * It is used in the CreatePipelineScreen and JobConfigurationSubScreen components.
 * It is also used to fetch the pipeline details from the API and to update the pipeline details in the API.
 * It is used to manage the state of the pipeline creation form and to provide the state to the components that need it.
 * It is used in the CreatePipelineScreen and JobConfigurationSubScreen components.
 @state {Object} filters - The filters for the pipeline
  @state {String} name - The name of the pipeline
  @state {Number} targetQuantity - The target quantity for the pipeline
  @state {Number} priority - The priority for the pipeline
  @state {Array} orderedJobs - The ordered jobs for the pipeline
  @state {String} pipelineId - The id of the pipeline
  @function fetchPipelineDetails - A function to fetch the pipeline details from the API
 */

import axios from "axios";
import React, { createContext, useState, useContext, useEffect } from "react";
import { showAlert } from "../components/common/userFeedback/CustomAlert";
import { BASE_URL } from "../config/apiConfig";
import { defaultFilters, defaultJobs } from "../config/pipelineConfig";
import { getHeaders } from "../utils/apiUtils";
import { v4 as uuidv4 } from "uuid";
import { jobNamesMap } from "../config/jobTypes";

const PipelineContext = createContext();

export const usePipelineContext = () => useContext(PipelineContext);

export const PipelineProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [targetQuantity, setTargetQuantity] = useState(0);
  const [priority, setPriority] = useState(1);
  const [orderedJobs, setOrderedJobs] = useState([]);
  const [pipelineId, setPipelineId] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [isArchived, setIsArchived] = useState(false);
  const [saving, setSaving] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [pipelineSummaryRaw, setPipelineSummaryRaw] = useState(null);

  const fetchPipelineDetails = async (pipelineId) => {
    const headers = await getHeaders();
    setPendingRequests((prev) => prev + 1);
    await axios
      .get(`${BASE_URL}tools/pipelines/get-pipeline/${pipelineId}`, headers)
      .then((response) => {
        const { data } = response;
        console.log("data", data);
        const { name, target_quantity, priority, jobs, datasets } = data;

        // Update pipeline-level details
        setName(name);
        setTargetQuantity(target_quantity);
        setPriority(priority);
        setDatasets(datasets[0]);

        // Map jobs to JobManager-compatible format
        const mappedJobs = jobs.map((job) => {
          const jobName = jobNamesMap[job.jobName];
          const defaultJobData = defaultJobs.find((job) => job.id === jobName);
          return {
            ...defaultJobData,
            id: job.pipeline_job_link_id || uuidv4(), // Unique identifier for each job
            graduationConfidence: job.graduationConfidence || 0, // QA confidence level
            users: job.users || [], // Associated users
            filters: job.filters || {}, // Job-specific filters
            labellingSet: job.labellingSet || {}, // Labelling set
            num_users: job.num_users || 2, // Number of users
          };
        });
        setPendingRequests((prev) => prev - 1);
        // Update ordered jobs
        setOrderedJobs(mappedJobs.filter(Boolean)); // Filter out null or invalid entries
      })
      .catch((error) => {
        setPendingRequests((prev) => prev - 1);
        showAlert(error);
        console.error("Error fetching pipeline details:", error);
      });
  };

  useEffect(() => {
    if (pipelineId) {
      fetchPipelineDetails(pipelineId);
    }
  }, [pipelineId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSaving(true);
    setPendingRequests((prev) => prev + 1);

    const jobs = orderedJobs.map((job) => ({
      id: job.id,
      jobName: job.jobName,
      graduationConfidence: job.graduationConfidence,
      labellingSet: job.labellingSet,
      users: job.users,
      num_users: job.num_users,
      filters: job.filters, // Each job's specific filters
    }));
    const submissionData = {
      name,
      targetQuantity,
      pipelineId,
      datasets: [datasets],
      isArchived: isArchived ? 1 : 0,
      priority,
      jobs,
    };
    setPipelineSummaryRaw(submissionData);
    const headers = await getHeaders();
    await axios
      .post(
        `${BASE_URL}tools/pipelines/save-or-update-pipeline`,
        submissionData,
        headers
      )
      .then((res) => {
        setSaving(false);
        showAlert(res);
        setPendingRequests((prev) => prev - 1);
      })
      .catch((error) => {
        setPendingRequests((prev) => prev - 1);
        showAlert(error);
        setSaving(false);
      });
  };

  const addJob = (jobType) => {
    const defaultJob = defaultJobs.find((job) => job.id === jobType);
    if (!defaultJob) {
      console.warn(`Job type ${jobType} not found.`);
      return;
    }

    const newJob = {
      ...defaultJob,
      id: uuidv4(),
      filters: {}, // Initialize with an empty filter dictionary
      graduationConfidence: 0,
      users: [],
    };

    setOrderedJobs((prevJobs) => [...prevJobs, newJob]);
  };

  const updateJob = (jobId, updatedAttributes) => {
    console.log("updatedAttributes", updatedAttributes);
    setOrderedJobs((prevJobs) =>
      prevJobs.map((job) =>
        job.id === jobId ? { ...job, ...updatedAttributes } : job
      )
    );
  };

  const removeJob = (jobId) => {
    setOrderedJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobId));
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleTargetQuantityChange = (event) => {
    const value = event.target.value;
    setTargetQuantity(value === "" ? null : Math.max(0, parseInt(value, 10)));
  };

  const handlePriorityChange = (event) => {
    const value = event.target.value;
    setPriority(
      value === "" ? null : Math.max(1, Math.min(10, parseInt(value, 10)))
    );
  };

  function resetState() {
    setName("");
    setTargetQuantity(0);
    setPriority(1);
    setOrderedJobs([]);
    setPipelineId(null);
    setDatasets(null);
    setIsArchived(false);
  }
  const contextValue = {
    name,
    setName,
    targetQuantity,
    setTargetQuantity,
    saving,
    priority,
    setPriority,
    handleNameChange,
    handleTargetQuantityChange,
    handlePriorityChange,
    orderedJobs,
    setOrderedJobs,
    setDatasets,
    isArchived,
    setIsArchived,
    datasets,
    resetState,
    pipelineId,
    setPipelineId,
    handleSubmit,
    saving,
    addJob,
    updateJob,
    removeJob,
    filters,
    pendingRequests,
    setPendingRequests,
    pipelineSummaryRaw,
  };

  return (
    <PipelineContext.Provider value={contextValue}>
      {children}
    </PipelineContext.Provider>
  );
};
