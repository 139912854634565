import {
  Box,
  Fade,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ClickableImage from "../..//layout/Interactive/Images/ClickableImage";
import { formatDistanceToNow } from "date-fns";

const renderValues = {
  style: {
    title: "Style",
    color: "grey",
    variant: "body",
  },
  graduated_proposals: {
    title: null,
    variant: "h3",
    color: "grey",
  },
};

const TimestampComponent = ({ timestamp }) => {
  const timeAgo = formatDistanceToNow(new Date(timestamp), { addSuffix: true });

  return <Typography color="inherit">{timeAgo}</Typography>;
};

const Proposal = ({ name, user, status, confidence, timestamp }) => {
  let backgroundColor = "white";
  switch (status) {
    case "PENDING":
      backgroundColor = "blue";

      break;
    case "APPROVED":
      backgroundColor = "green";
      break;
    case "DECLINED":
      backgroundColor = "red";
      break;
    default:
      backgroundColor = "orange";
      break;
  }
  let display_confidence = 0;
  try {
    display_confidence = Math.round(confidence * 100);
  } catch (error) {}

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography variant="body" color="inherit">
            {name}
          </Typography>{" "}
          {/* Name of the proposal */}
          <Typography color="inherit">{user}</Typography>{" "}
          {/* User information */}
          <Typography color="inherit">{status}</Typography>{" "}
          <Typography color="inherit">{display_confidence}%</Typography>{" "}
          <TimestampComponent timestamp={timestamp} />{" "}
          {/* Status of the proposal */}
        </React.Fragment>
      }
    >
      <Paper
        sx={{
          border: `2px solid ${backgroundColor}`,
          display: "flex",
          flexDirection: "column",
          p: 0.5,
        }}
      >
        <Typography
          variant="h8"
          sx={{
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
          }}
        >
          {name}{" "}
          <Typography
            variant="body_sm"
            sx={{
              overflow: "hidden",
            }}
          >
            {display_confidence}%
          </Typography>
        </Typography>
        <Typography
          variant="body_sm"
          sx={{
            overflow: "hidden",
          }}
        >
          {user && user.length > 8 ? `${user.slice(0, 8)}...` : user}
        </Typography>
      </Paper>
    </Tooltip>
  );
};
const PlainText = ({ value_type, value }) => {
  if (!renderValues[value_type]) return;
  const { title, color, variant } = renderValues[value_type];
  return (
    <Typography variant={variant} sx={{ color: color }}>
      {title ? `${title}: ` : null} {value}
    </Typography>
  );
};
function DataPointRenderComponent(props) {
  const { value, value_type, showProposals } = props;
  switch (typeof value) {
    case "string":
      console.log(`value_type: ${value_type}`);
      return <PlainText value={value} value_type={value_type} />;
    case "object":
      if (value_type === "proposals") {
        return (
          <div style={{ position: "relative", height: "100%" }}>
            {/* Ensure it has a height */}
            <Fade in={showProposals} timeout={500}>
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  bottom: 0, // Positions the box at the bottom
                  left: 0,
                  right: 0,
                  zIndex: 2, // Ensure it's above other content
                  borderRadius: 3,
                }}
              >
                <Grid container>
                  {value?.map((proposal) => {
                    const { name, user, status } = proposal;
                    return (
                      <Grid
                        item
                        xs={4}
                        sx={{
                          p: 0.25,
                        }}
                      >
                        <Proposal {...proposal} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Fade>
          </div>
        );
      }
      return null;
    default:
      return null;
  }
}

function GarmentCard({ garmentData, fixProposals, imageNum = null }) {
  const { proposals, garment_id, uuid_public_id, confidence, brand } =
    garmentData;
  console.log(`imageNum: ${imageNum}`);
  const dataKeys = Object.keys(garmentData);
  const [showProposals, setShowProposals] = useState();
  console.log(`uuid_public_id: ${uuid_public_id}`);
  const image = null;
  const { palette } = useTheme();

  return (
    <Paper
      sx={{
        height: "250px", // Fixed height
        p: 1,
        m: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onMouseEnter={() => setShowProposals(true)}
      onMouseLeave={() => setShowProposals(false)}
    >
      <Box
        sx={{
          display: "flex",
          bgcolor: "rgba(255,255,255,0.5) !important",
        }}
      >
        <Typography
          variant="h8"
          sx={{
            fontSize: "11px",
          }}
        >
          {brand}
        </Typography>
      </Box>

      <Box
        sx={{
          borderRadius: 0.1,
          width: "100%",
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          my: 0.5,
        }}
      >
        <ClickableImage
          image={`https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${uuid_public_id}-${
            imageNum ?? 1
          }.jpg`}
        />
      </Box>

      <Box
        sx={{
          justifyContent: "flex-end",
          textAlign: "right",
          backgroundColor: "rgba(255,255,255,0.5)",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "11px",
          }}
        >
          ID:{garment_id}
        </Typography>
        {confidence ? (
          <Typography
            variant="body1"
            sx={{
              fontSize: "11px",
            }}
          >
            (x1000) {Math.round(confidence * 1000)}%
          </Typography>
        ) : null}
      </Box>

      <Box>
        {dataKeys.map((value_type) => (
          <DataPointRenderComponent
            value={garmentData[value_type]}
            value_type={value_type}
            showProposals={showProposals || fixProposals}
          />
        ))}
      </Box>
    </Paper>
  );
}

export default GarmentCard;
