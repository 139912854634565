import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import useSize from "../../../../hooks/useSize";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
const PlotlyRenderers = createPlotlyRenderers(Plot);

const AggregationLinkingProgressPivotTable = forwardRef(({fillPage=false, sx={}, width=null, height=null}, ref) => {
  const [aggregationLinkingPivotData, setAggregationLinkingPivotData] = useState([]);
  const [pivotState, setPivotState] = useState({});
  const { palette, typography } = useTheme();
  const [date, setDate] = React.useState([dayjs().subtract(14, "day"), dayjs()]);
  const [dataLoading, setDataLoading] = useState(false);
  const windowSize = useSize();
  const [datasets, setDatasets] = useState([]);
  const [datasetId, setDatasetId] = useState(12);
  const chartWidth = fillPage ? windowSize[0] : (width ?? 0.5*windowSize[0])
  const chartHeight = fillPage ? 0.3*windowSize[1] : (height ?? 0.25*windowSize[1])

  const refreshData = async () => {
    setAggregationLinkingPivotData([]);
    setDataLoading(true);
    const aggregationLinkingProgressData = await fetchData()
    setAggregationLinkingPivotData(aggregationLinkingProgressData);
  };

  useImperativeHandle(ref, () => {
    return {
      refreshData
    }
  })

  const handleDatasetChange = (event) => {
    setDatasetId(event.target.value);
  };

  
  useEffect(() => {
    const fetchDatasets = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/admin/datasets/listall`, headers)
        .then((res) => {
          // Reset form fields (optional)
          setDatasets([...res.data.data.filter(x => !x.is_archived)]);
        })
        .catch((err) => {
          showAlert(err);
        });
    };

    fetchDatasets();
  }, []);

  useEffect(() => {
    const pivotState = {
      data: aggregationLinkingPivotData,
      rows: [],
      cols: ['day'],
      aggregatorName: 'Integer Sum',
      vals: ['count_above_0_edges'],
      rendererName: 'Area Chart',
      //sorters: {
      //    Meal: sortAs(['Lunch', 'Dinner']),
      //    'Day of Week': sortAs([
      //        'Thursday',
      //        'Friday',
      //        'Saturday',
      //        'Sunday',
      //    ]),
      //},
      plotlyOptions: {width: chartWidth, height: chartHeight}
      //plotlyConfig: {},
      //tableOptions: {
      //    clickCallback: function(e, value, filters, pivotData) {
      //        var names = [];
      //        pivotData.forEachMatchingRecord(filters, function(
      //            record
      //        ) {
      //            names.push(record.Meal);
      //        });
      //        alert(names.join('\n'));
      //    },
      //},
    }

    setPivotState(pivotState)
  }, [aggregationLinkingPivotData])

  const fetchData = async () => {
    setDataLoading(true);
    try {
      let headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/analytics/pivots/aggregation/linking-progress`,
        {
          datasetId: datasetId ?? 12,
          startDate: date[0].toISOString(),
          endDate: date[1].toISOString(),
        },
        headers
      );
      const newData = response.data.data;
      setDataLoading(false);
      return newData;
    } catch (error) {
      setDataLoading(false);
      console.error(`Error fetching proposals for Aggregation Linking Progress:`, error);
      showAlert(error);
      return []; // Return whatever was fetched before the error
    }
  };

  useEffect(() => {
    refreshData()
  }, [date, datasetId])

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant={fillPage ? "h2" : "h4"} sx={{
          p: 1, pt: 0
        }} color="primary.800">
          Aggregation Linking Progress Pivot Table
        </Typography>
        <Box
          sx={{
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{ start: "Start", end: "End" }}
              onChange={(newValue) => setDate(newValue)}
              value={date}
            />
          </LocalizationProvider>

          <FormControl margin="normal" sx={{mb: 1.5, ml: 2}}>
            <InputLabel shrink={true} id="dataset-label">
              Dataset
            </InputLabel>
            <Select
              labelId="dataset-label"
              id="dataset"
              label="Dataset"
              notched={true}
              value={datasetId}
              onChange={handleDatasetChange}
            >
              {datasets.map((dataset) => (
                <MenuItem value={dataset.id}>{dataset.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {!ref && 
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={refreshData}>
              Refresh
            </Button>
          </Box>}
          
        </Box>

        <Box
          sx={{
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            ...sx
          }}
        >
          <PivotTableUI
            onChange={setPivotState}
            {...pivotState}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
})

export default AggregationLinkingProgressPivotTable;
