import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SelectableList from "../../../components/features/GarmentAttribute/SelectableList";
import ImageWindow from "../../../components/layout/Interactive/ImageWindow";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import MassImageApprovalsFrame from "../../../components/layout/Interactive/MassImageApprovalsFrame";
import { jobQaMap, jobTypes } from "../../../config/jobTypes";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import Slider from "@mui/material/Slider";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import StyledButton from "../../../components/ui/buttons/StyledButton";

function ImageAttributeQATool() {
  const {
    jobDetails,
    jobDataLoaded,
    toolType,
    allPipelineJobs,
    shownAttributes,
  } = useToolContext();
  const { palette } = useTheme();
  const [numUsers, setNumUsers] = useState(4);
  const [tagSet, setTagSet] = useState([]);
  const [garments, setGarments] = useState([]);
  const [rejectedTags, setRejectedTags] = useState([]);
  const [selectAllGarments, setSelectAllGarments] = useState(false);

  const [selectedTag, setSelectedTag] = useState(null);
  const [loading, setLoading] = useState(true);
  const [additionalPossiblePipelineJobs, setAdditionalPossiblePipelineJobs] =
    useState([]);
  const [additionalPipelineJobs, setAdditionalPipelineJobs] = useState([]);
  const { trackEvent } = useAmpltiudeEventTrackingContext();
  const [qaCountObjects, setQaCountObjects] = useState(null);

  //Axios Calls
  const fetchTagSet = async () => {
    const headers = await getHeaders();
    axios
      .get(
        `${BASE_URL}tools/jobs/tag-set/${jobDetails.pipeline_job_link_id}`,
        headers
      )
      .then((res) => {
        setTagSet(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchPipelineJobIds = async () => {
    const headers = await getHeaders();
    const jobsToFetch = jobQaMap[toolType];
    axios
      .get(
        `${BASE_URL}tools/pipelines/list-pipeline-jobs/${jobsToFetch}`,
        headers
      )
      .then((res) => {
        setAdditionalPossiblePipelineJobs(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        showAlert(err);
      });
  };

  const fetchNextItemBatch = async () => {
    const headers = await getHeaders();
    const jobsToQa = jobQaMap[toolType];
    const jobIdsToQa = allPipelineJobs
      .filter((job) => jobsToQa.includes(job.job))
      .map((job) => job.pipeline_job_link_id);
    const fullListofIdsToQA = [...additionalPipelineJobs, ...jobIdsToQa];
    const submissionData = {
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      pipelineJobIdsToQa: fullListofIdsToQA,
      selectedTag: selectedTag,
      userCount: numUsers,
      jobID: jobDetails.job_id,
      shownAttributes: shownAttributes,
    };
    setLoading(true);
    axios
      .post(
        `${BASE_URL}tools/summon/next-item/qa/image-attribute`,
        submissionData,
        headers
      )
      .then((res) => {
        setGarments(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setGarments([]);
        setLoading(false);
        showAlert(err);
      });
  };

  const fetchQaCount = async () => {
    const headers = await getHeaders();
    const jobsToQa = jobQaMap[toolType];
    const jobIdsToQa = allPipelineJobs
      .filter((job) => jobsToQa.includes(job.job))
      .map((job) => job.pipeline_job_link_id);
    const fullListofIdsToQA = [...additionalPipelineJobs, ...jobIdsToQa];
    const submissionData = {
      graduationConfidence: jobDetails.qa_confidence,
      toolType: toolType,
      pipelineJobIdsToQa: fullListofIdsToQA,
      userCount: numUsers,
      jobID: jobDetails.job_id,
      shownAttributes: shownAttributes,
      tags: tagSet,
    };
    setLoading(true);
    axios
      .post(`${BASE_URL}tools/jobs/qa/count`, submissionData, headers)
      .then((res) => {
        setQaCountObjects(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setQaCountObjects([]);
        setLoading(false);
        showAlert(err);
      });
  };

  const submitItems = async () => {
    const headers = await getHeaders();
    setLoading(true);
    const rejectedIds = rejectedTags.map((t) => t.id);
    let approved = garments
      .filter((image) => !rejectedIds.includes(image.garment_id))
      .map((image) => {
        return { garment_id: image.garment_id, image_num: image.image_num };
      });
    let rejected = garments
      .filter((image) => rejectedIds.includes(image.garment_id))
      .map((image) => {
        return { garment_id: image.garment_id, image_num: image.image_num };
      });

    const data = {
      approved: approved,
      rejected: rejected,
      proposal_value_id: selectedTag,
      jobId: jobDetails.job_id,
      pipelineJobId: jobDetails.pipeline_job_link_id,
      toolType: toolType,
    };
    await axios
      .post(`${BASE_URL}tools/classify/qa/image-attribute`, data, headers)
      .then((res) => {
        fetchNextItemBatch();
        fetchQaCount();
        setRejectedTags([]);
        setSelectAllGarments(false);

        trackEvent("ProposalQA", {
          approvedGarmentIds: approved.map(x => x.garment_id),
          rejectedGarmentIds: rejected.map(x => x.garment_id),
          pipelineJobId: jobDetails.pipeline_job_link_id,
          pipelineName: jobDetails.pipeline_name,
          jobId: jobDetails.job_id,
          jobName: jobDetails.job,
          toolType: toolType,
          toolName: "ImageAttributeQATool",
          confidence: jobDetails.qa_confidence,
          skipped: approved.length === 0,
          qa: true,
        });
      })
      .catch((err) => {
        console.log(err);
        showAlert(err);
      });
  };

  //Use Effects
  useEffect(() => {
    if (jobDataLoaded) {
      fetchTagSet();
      fetchPipelineJobIds();
    }
  }, [jobDataLoaded]);

  useEffect(() => {
    if (selectedTag) {
      fetchNextItemBatch();
    }
  }, [selectedTag, numUsers, additionalPipelineJobs]);

  useEffect(() => {
    if (jobDataLoaded && tagSet && tagSet.length > 0) {
      fetchQaCount();
    }
  }, [tagSet, toolType, numUsers, shownAttributes, additionalPipelineJobs]);

  const getQaCountFromTagId = (tagId) => {
    // get qaCountObject name from qaCount
    const qaCountObjectsFiltered = qaCountObjects.filter(
      (qaCountObject) => qaCountObject.tag_id === tagId
    );
    return qaCountObjectsFiltered.length;
  };

  //Handlers
  const handleSliderChange = (event, newValue) => {
    setNumUsers(newValue);
  };

  const handleSelectAllGarments = (selectOrDeselectAll) => {
    const localRejectedTags = garments.map((garm) => {
      return {
        id: garm.garment_id,
        proposed_timestamp: getCurrentTimestamp(),
      };
    });

    setRejectedTags(selectOrDeselectAll ? localRejectedTags : []);
    setSelectAllGarments(!selectAllGarments);
  };

  return (
    <Box
      sx={{
        p: 2,
        pt: 0,
      }}
    >
      <LoadingOverlay open={loading} />
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between !important",
          alignItems: "center",
        }}
        spacing={2}
      >
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography variant="h3">Image Attribute QA Tool</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              flex: 1,
              alignItems: "center",
              pl: 1,
              pr: 1,
            }}
          >
            <Select
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
              sx={{
                width: "100%",
                mt: 0.8,
              }}
            >
              <MenuItem key={null} value={null}>
                Select Tag
              </MenuItem>
              {tagSet.map((tag, tagIndex) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name} (
                  {qaCountObjects && getQaCountFromTagId(tag.id)
                    ? getQaCountFromTagId(tag.id)
                    : "0"}
                  )
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              flex: 1,
              alignItems: "center",
              pl: 1,
              pr: 1,
            }}
          >
            <Autocomplete
              multiple
              options={additionalPossiblePipelineJobs}
              disableCloseOnSelect
              getOptionLabel={(option) => option.pipeline}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} style={{ marginRight: 8 }} />
                  {option.pipeline}
                </li>
              )}
              value={additionalPipelineJobs.map((jobId) =>
                additionalPossiblePipelineJobs.find((job) => job.id === jobId)
              )}
              onChange={(event, newValue) => {
                setAdditionalPipelineJobs(newValue.map((item) => item.id));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Pipelines"
                  margin="normal"
                />
              )}
              sx={{ width: "100%" }}
            />
          </Box>

          <Box
            sx={{
              justifyContent: "center",
              flex: 1,
              alignItems: "center",
              pl: 1,
              pr: 1,
            }}
          >
            <Typography id="input-slider" variant="body" gutterBottom>
              Select a number of users:
            </Typography>
            <Slider
              value={numUsers}
              min={1}
              max={10}
              marks
              step={1}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="input-slider"
            />
            <Typography variant="body">Selected Number: {numUsers}</Typography>
          </Box>

          <Box
            sx={{
              justifyContent: "center",
              flex: 1,
              alignItems: "center",
              pl: 1,
              pr: 1,
            }}
          >
            <StyledButton
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => handleSelectAllGarments(!selectAllGarments)}
            >
              {selectAllGarments ? `DESELECT` : `SELECT`} ALL
            </StyledButton>

            <StyledButton
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={() => submitItems()}
              sx={{ ml: 1 }}
            >
              SUBMIT
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
      {selectedTag ? (
        (garments.length === 0) & !loading ? (
          <NoDataScreenCover showBackButton={false} />
        ) : (
          <MassImageApprovalsFrame
            tags={tagSet}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
            garments={garments}
            rejectedTags={rejectedTags}
            setRejectedTags={setRejectedTags}
            shownAttributes={shownAttributes}
            toolType={toolType}
          />
        )
      ) : (
        <TakeActionScreenCover />
      )}
    </Box>
  );
}

function WrappedImageAttributeQATool() {
  //Job Pipeline ID
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <ImageAttributeQATool />
    </ToolProvider>
  );
}

export default WrappedImageAttributeQATool;
