import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import ClickableImage from "../../../components/layout/Interactive/Images/ClickableImage";

function GarmentPanel({
  garment,
  selectedGarments,
  ignoredGarments,
  setIgnoredGarments,
  setSelectedGarments,
  index,
  imageIndex=1,
  xs = 2,
  height="100%"
}) {
  const { uuid_public_id, score, garment_id } = garment;
  const isSelected = selectedGarments.hasOwnProperty(garment_id);
  const onImageClick = () => toggleSelection(garment_id, score);
  const theme = useTheme();
  const toggleSelection = (garmentId, garmentScore) => {
    setSelectedGarments((prevSelected) => {
      const currentTimestamp = new Date().toISOString();
      if (prevSelected.hasOwnProperty(garmentId)) {
        // If the garmentId is already a key, remove it and add to ignored
        const updatedSelection = { ...prevSelected };
        delete updatedSelection[garmentId];

        setIgnoredGarments((prevIgnored) => ({
          ...prevIgnored,
          [garmentId]: {
            score: garmentScore,
            timestamp: currentTimestamp,
            rank: index,
          },
        }));

        return updatedSelection;
      } else {
        // Otherwise, add the garmentId to selected and remove from ignored
        const updatedIgnored = { ...ignoredGarments };
        delete updatedIgnored[garmentId];

        setIgnoredGarments(updatedIgnored);

        return {
          ...prevSelected,
          [garmentId]: {
            score: garmentScore,
            timestamp: currentTimestamp,
            rank: index,
          },
        };
      }
    });
  };
  return (
    <Grid item xs={xs}>
      <Box
        sx={{
          height: height ?? "22.5vh", // Fixed height
          backgroundColor: isSelected ? "primary.main" : "primary.light",
          border: "1px solid black",
          p: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body_xs" style={{ display: "block" }}>
          Score: {score}
        </Typography>
        <Typography variant="body_xs" style={{ display: "block" }}>
          ID: {garment_id}
        </Typography>

        <Box
          onClick={onImageClick}
          sx={{
            borderRadius: 0.1,
            width: "100%",
            height: "100%",
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {garment && imageIndex && imageIndex > 0 && imageIndex <= garment.num_images
          ? <ClickableImage
              image={`https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${uuid_public_id}-${imageIndex}.jpg`}
            />
          : <Typography variant="h4" sx={{color: theme.palette.primary[400]}}>No image.</Typography>}
        </Box>
        
        
      </Box>
    </Grid>
  );
}

export default GarmentPanel;
