import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { ToolProvider, useToolContext } from "../../../contexts/ToolContext";
import { getHeaders } from "../../../utils/apiUtils";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@emotion/react";
import SelectableList from "../../../components/features/GarmentAttribute/SelectableList";
import ImageWindow from "../../../components/layout/Interactive/ImageWindow";
import KeyEventHandler from "../../../contexts/KeyEventHandler";
import MassImageApprovalsFrame from "../../../components/layout/Interactive/MassImageApprovalsFrame";
import { jobQaMap, jobTypes } from "../../../config/jobTypes";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import Slider from "@mui/material/Slider";
import TakeActionScreenCover from "../../../components/common/userFeedback/TakeActionScreenCover";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import NoDataScreenCover from "../../../components/common/userFeedback/NoDataScreenCover";
import CustomModal from "../../../components/common/userFeedback/CustomModal";
import SeedGarment from "../../../components/ui/cards/SeedGarment";
import { StyledButton } from "../../../components/ui/buttons/StyledButton";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import { useAmpltiudeEventTrackingContext } from "../../../contexts/AmplitudeTrackingContext";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";
import DatasetDropdown from "../../../components/forms/Dropdowns/DatasetDropdown";
import PineconeIndexDropdown from "../../../components/forms/Dropdowns/PineconeIndexDropdown";

function AggregationQATool() {
  const [garments, setGarments] = useState([]);
  const [rejectedTags, setRejectedTags] = useState([]);
  const [pipelineJobIds, setPipelineJobIds] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingLargestGroup, setLoadingLargestGroup] = useState(false);
  const [loadingGarments, setLoadingGarments] = useState(false);
  const [presentedTimeStamp, setPresentedTimeStamp] = useState(null);
  const [groupRepresentativeChanged, setGroupRepresentativeChanged] =
    useState(false);
  const {
    inputFilters,
    jobDetails,
    toolType,
    filterHardness,
    datasets,
    jobDataLoaded,
    allPipelineJobs,
    shownAttributes,
  } = useToolContext();
  const theme = useTheme();
  const { trackEvent } = useAmpltiudeEventTrackingContext();
  const [associatedGarmentsCount, setAssociatedGarmentsCount] = useState(0);
  const [rejectedGarmentsCount, setRejectedGarmentsCount] = useState(0);

  const [largestGroup, setLargestGroup] = useState({
    garment_count: 0,
    group_uuid: null,
  });
  const [groupRepresentative, setGroupRepresentative] = useState(null);

  const [numberOfColumns, setNumberOfColumns] = useState(4); // Default to 3 columns
  const [limit, setLimit] = useState(numberOfColumns * 3);
  const [totalGarmentsSeen, setTotalGarmentsSeen] = useState(0);

  useEffect(() => {
    setLimit(numberOfColumns * 3);
  }, [numberOfColumns]);

  async function fetchLargestGroup() {
    const headers = await getHeaders();
    setLoadingLargestGroup(true);
    const jobsToQa = jobQaMap[toolType];
    const jobIdsToQa = allPipelineJobs
      .filter((job) => jobsToQa.includes(job.job))
      .map((job) => job.pipeline_job_link_id);
    setPipelineJobIds(jobIdsToQa)

    const submissionData = {
      toolType: toolType,
      pipelineJobIdsToQa: jobIdsToQa,
      jobID: jobDetails.job_id,
      datasets: datasets,
    };
    console.log(`jobID: ${jobDetails.job_id}`)
    try {
      const res = await axios.post(
        `${BASE_URL}tools/summon/next-item/qa/aggregation-new`,
        submissionData,
        headers
      );
      console.log(res.data.data);
      setGroupRepresentative(res.data.data.representative_garment);
      setLargestGroup(res.data.data.group);
      setLoadingLargestGroup(false);
    } catch (error) {
      console.log(error);
      setLoadingLargestGroup(false);
      showAlert(error);
    }
  }
  async function fetchGarments() {
    const headers = await getHeaders();
    if (!largestGroup?.group_uuid) {
      return;
    }
    if (loadingGarments) {
      return;
    }
    setLoadingGarments(true);

    try {
      const res = await axios.post(
        `${BASE_URL}tools/summon/list-associations/qa/aggregation-new`,
        {
          group_uuid: largestGroup.group_uuid,
          limit: limit,
        },
        headers
      );
      console.log(res.data.data);
      setGarments(res.data.data);
      setTotalGarmentsSeen(
        (prev) =>
          prev +
          (garments.length)
      );
      setLoadingGarments(false);
      setPresentedTimeStamp(getCurrentTimestamp());
      if (res.data.data.length === 0) {
        setRejectedTags([]);
        setLargestGroup(null);
        fetchLargestGroup();
        setTotalGarmentsSeen(0);
      }
    } catch (error) {
      setLoadingGarments(false);
      showAlert(error);
    }
  }

  const handleSubmit = async () => {
    // Instead of merging rejectedTags into the approved set, we now treat them as truly rejected.
    // The 'combinedArray' will now represent the garments we are approving, i.e., all garments
    // that are NOT in rejectedTags.

    // Create a set of rejected garment IDs for easy lookup
    const rejectedIds = new Set(rejectedTags.map((tag) => tag.id));

    // The combinedArray now will be all garments that are NOT in rejectedTags.
    // These garments represent the approved set.
    const combinedArray = garments
      .filter((g) => !rejectedIds.has(g.garment_id))
      .map((g) => ({
        ...g,
        presented_timestamp: presentedTimeStamp,
      }));

    // If the representative garment changed, also include it in the approvals.
    // (Same logic as before, but now this goes into the approved set, not the rejected set.)
    if (groupRepresentativeChanged) {
      combinedArray.push({
        ...groupRepresentative,
        is_group_representative: true,
        presented_timestamp: presentedTimeStamp,
      });
    }

    // The approved garments are now those in 'combinedArray'
    const approved = combinedArray.map((g) => g.garment_id);

    // The rejected garments are now those explicitly listed in rejectedTags
    const rejected = rejectedTags.map((tag) => tag.id);

    // Update counts
    setAssociatedGarmentsCount((prev) => prev + combinedArray.length);
    setRejectedGarmentsCount((prev) => prev + rejectedTags.length);

    const params = {
      approved,
      rejected,
      proposal_group_uuid: largestGroup.group_uuid,
    };

    try {
      const headers = await getHeaders();
      await axios.post(
        `${BASE_URL}tools/classify/qa/aggregation-new`,
        params,
        headers
      );
      // Reset states after successful submission
      setRejectedTags([]);
      setGroupRepresentativeChanged(false);
      setGarments([]);
      fetchGarments();

      trackEvent("AggregationQA", {
        datasetId: datasets,
        garmentId: groupRepresentative["garment_id"],
        aggregations: associatedGarmentsCount,
        totalAggregations: combinedArray.length,
        selectedGarmentIds: Object.keys(approved),
        rejectedGarmentIds: Object.keys(rejected),
        garmentsSelected: Object.keys(approved).length,
        garmentsRejected: Object.keys(rejected).length,
        garmentsSeen: totalGarmentsSeen,
        jobId: 17,
        pipelineJobId: jobDetails.pipeline_job_link_id,
        pipelineJobIdsToQA: pipelineJobIds,
        pipelineName: jobDetails.pipeline_name,
        jobName: "aggregationQA",
        toolType: "AGGREGATION_QA",
        toolName: "AggregationQATool",
        qa: true,
        skipped: combinedArray.length === 0, //"Skipped" is no selected garments
      });
    } catch (err) {
      console.error(err)
      showAlert(err);
    }
  };

  useEffect(() => {
    if (jobDataLoaded) {
      fetchLargestGroup();
    }
  }, [jobDataLoaded]);

  useEffect(() => {
    fetchGarments();
  }, [largestGroup, limit]);

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      }}
    >
      <LoadingOverlay
        open={loadingGarments || loadingLargestGroup}
        text={
          loadingGarments
            ? "Loading Garments"
            : loadingLargestGroup
            ? "Loading Largest Group"
            : ""
        }
      />
      <Grid
        container
        width="100%"
        spacing={2}
        sx={{
          alignItems: "flex-end",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            justifyContent: "space-between",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Typography variant="h2">Aggregation QA</Typography>
          <StyledButton
            variant="contained"
            color="primary"
            sx={{ ml: 2, color: theme.palette.primary[100] }}
            disabled={
              loadingGarments || loadingLargestGroup || !groupRepresentative
            }
            onClick={handleSubmit}
          >
            Next Page
          </StyledButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            pb: 2,
          }}
        >
          <Select
            value={numberOfColumns}
            onChange={(e) => setNumberOfColumns(e.target.value)}
            displayEmpty
            sx={{ ml: 2 }}
          >
            <MenuItem value={4}>4 Column</MenuItem>
            <MenuItem value={5}>5 Columns</MenuItem>
            <MenuItem value={6}>6 Columns</MenuItem>
            <MenuItem value={7}>7 Columns</MenuItem>
            <MenuItem value={8}>8 Columns</MenuItem>
          </Select>
          <Divider
            sx={{
              m: 1,
            }}
          />
        </Grid>
      </Grid>
      <Divider
        sx={{
          m: 1,
        }}
      />
      {!(garments.length === 0) && !loadingGarments && !loadingLargestGroup ? (
        <Grid container>
          <Grid xs={9}>
            <MassImageApprovalsFrame
              garments={garments}
              rejectedTags={rejectedTags}
              setRejectedTags={setRejectedTags}
              disabled={false}
              reject={true}
              numberOfColumns={numberOfColumns}
            />
          </Grid>
          <Grid
            xs={3}
            sx={{
              mt: 1,
            }}
          >
            <Typography variant="h3">Group Representative</Typography>

            <Box
              sx={{
                border: `1px solid ${theme.palette.primary[400]}`,
                borderRadius: 1,
                p: 2,
              }}
            >
              <SeedGarment garment={groupRepresentative} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <TakeActionScreenCover />
      )}

      <Divider
        sx={{
          m: 1,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          Page: {page} /{" "}
          {largestGroup?.garment_count <= 0
            ? 0
            : Math.ceil(largestGroup?.garment_count / limit)}
        </Typography>
        <Typography variant="h4" color="success.main">
          {associatedGarmentsCount}
        </Typography>
        <Typography variant="h4" color="error.main">
          {rejectedGarmentsCount}
        </Typography>
        <Typography>Group Size: {largestGroup?.garment_count}</Typography>
        <Typography>UUID: {largestGroup?.group_uuid}</Typography>
      </Box>
    </Box>
  );
}
function WrappedAggregationQATool() {
  const { id } = useParams();
  return (
    <ToolProvider id={id}>
      <AggregationQATool />
    </ToolProvider>
  );
}

export default WrappedAggregationQATool;
