import React from "react";
import { useJobConfigurationContext } from "../../../../contexts/JobConfigurationContext";
import ConfigurationDialogBase from "./ConfigurationDialogBase";
import NavigationStepper from "../NavigationStepper";
import GeneralConfigurationSection from "./Components/GeneralConfigurationSection";
import FilterSection from "./Components/FilterSection";
import UserSection from "./Components/UserSection";

function JobConfigurationDialog() {
  const {
    activeJob,
    currentScreen,
    setCurrentScreen,
    filters,
    setFilters,
    graduationConfidence,
    setGraduationConfidence,
    labellingSet,
    setLabellingSet,
    numberOfUsers,
    setNumberOfUsers,
    selectedUsers,
    setSelectedUsers,
    validateFields,
    saveJob,
    errors,
  } = useJobConfigurationContext();

  const handleSave = () => {
    if (validateFields()) {
      saveJob();
    }
  };

  return (
    <ConfigurationDialogBase
      activeJob={activeJob}
      handleSave={handleSave}
      error={errors.general}
    >
      <NavigationStepper
        currentScreen={currentScreen}
        setCurrentScreen={setCurrentScreen}
        numScreens={3}
      />
      {currentScreen === 0 && <GeneralConfigurationSection />}
      {currentScreen === 1 && (
        <FilterSection filters={filters} setFilters={setFilters} />
      )}
      {currentScreen === 2 && (
        <UserSection
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      )}
    </ConfigurationDialogBase>
  );
}

export default JobConfigurationDialog;
