import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import { showAlert } from "../../../../components/common/userFeedback/CustomAlert";
import {
  AnalyticsButton,
  DataViewButton,
  ViewButton,
} from "../../../../components/ui/buttons/TableButtons";
import { getTotalHeaderHeight } from "@mui/x-data-grid/internals";
import { APP_BAR_HEIGHT } from "../../../../components/layout/AppBar/AppBarComponent";
import StyledDataGrid from "../../../../components/ui/dataViews/StyledDataGrid";

function PipelineJobsDashboard() {
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  // State for handling dialog open/close

  useEffect(() => {
    const fetchJobs = async () => {
      let headers = await getHeaders();
      axios
        .get(`${BASE_URL}tools/jobs/all-jobs`, headers)
        .then((res) => {
          // Reset form fields (optional)
          setJobs(res.data.data);
        })
        .catch((err) => {
          showAlert(err);
        });
    };

    fetchJobs();
  }, []);

  const columns = [
    { field: "pipeline_job_id", headerName: "id", flex: 1 },
    { field: "name", headerName: "Name", flex: 1, editable: false },
    { field: "pipeline_id", headerName: "Pipeline ID", flex: 1 },
    { field: "pipeline_name", headerName: "Pipeline Name", flex: 1 },
    {
      field: "pipeline_is_archived",
      headerName: "Archived",
      flex: 1,
      valueGetter: (params) => (params.value === 1 ? "Yes" : "No"), // Convert boolean to "Yes" or "No"
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              backgroundColor: params.value === "Yes" ? "red" : "green",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <span>{params.value}</span>
        </div>
      ),
    },
    { field: "pipeline_job_index", headerName: "Pipeline Index", flex: 1 },

    {
      field: "analytics",
      headerName: "",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <AnalyticsButton
          action={() => navigate(`analytics/${params.row.pipeline_job_id}`)}
        />
      ),
    },
    {
      field: "dataview",
      headerName: "",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <DataViewButton
          action={() => navigate(`dataview/${params.row.pipeline_job_id}`)}
        />
      ),
    },

    // Add more columns as needed based on your SQL table structure
  ];

  return (
    <Box
    sx={{
      height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      p:2,
      paddingTop: 2
    }} 
    >
      <Typography
        variant="h2"
        sx={{
          mb: 2,
        }}
      >
        Pipeline Job Analytics
      </Typography>

      <StyledDataGrid
        rows={jobs}
        columns={columns}
        getRowId={(row) => row.pipeline_job_id}
        disableRowSelectionOnClick
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  field: "pipeline_is_archived",
                  operator: "equals",
                  value: "No",
                },
              ],
            },
          },
        }}
      />
    </Box>
  );
}

export default PipelineJobsDashboard;
