import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
  Typography,
} from "@mui/material";

function ImageScrollPreview({ images, sx, showByIndex, image_index, spacing=0.5, imgHeight="12rem" }) {
  const theme = useTheme();

  return images && images.length > 0 ? (
    <Grid
      container
      spacing={spacing}
      sx={{
        height: "100%",
        paddingLeft: 1,
        paddingRight: 1,
        alignItems: "center",
        justifyContent: "space-evenly",
        marginBottom: 0,
        overflowY: images.length < 3 ? "hidden" : "scroll",
        scrollbarWidth: "none", // Hide the scrollbar for firefox
        "&::-webkit-scrollbar": {
          display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
        },
        "&-ms-overflow-style:": {
          display: "none", // Hide the scrollbar for IE
        },
        ...sx,
      }}
    >
      {showByIndex ? (
        image_index === 0 || image_index > images.length - 1 
        ? <Typography variant="h4" sx={{color: theme.palette.primary[300]}}>No image.</Typography>
        : <img
          src={images[image_index - 1]}
          alt={images[image_index - 1]}
          style={{
            height: "100%",
            paddingLeft: 8,
            width: "100%",
            objectFit: "contain",
            margin: "auto",
            paddingTop: 12,
          }}
        /> 
      ) : images.length > 1 ? (
        images.map((image, index) => (
          <Grid item xs={6}>
            <img
              key={index}
              src={image}
              alt={`Garment ${index}`}
              style={{
                height: imgHeight, 
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Grid>
        ))
      ) : (
        <img
          src={images[0]}
          alt={images[0]}
          style={{
            height: "100%",
            paddingLeft: 8,
            width: "100%",
            objectFit: "contain",
            margin: "auto",
            paddingTop: 12,
          }}
        />
      )}
    </Grid>
  ) : (
    <Grid container spacing={2} >
      <Typography variant="h4" sx={{color: theme.palette.primary[300]}}>No image.</Typography>
    </Grid>
  );
}

export default ImageScrollPreview;
