/*
  This file contains all of the events tracked using Amplitude through Bridge.
*/

export enum CustomTrackedEvent {
  Login,
  Logout,
  StartPipelineSession,
  EndPipelineSession,
  Proposal,
  ProposalQA,
  Aggregation,
  AggregationQA,
  AggregationQASetGroupRepresentative,
  UserInactive,
  UserActive,
}

//Default events from Amplitude
//https://community.amplitude.com/product-updates/default-event-tracking-as-part-of-our-browser-sdk-2-0-2462
export enum AmplitudeTrackedEvent {
  PageView,
  FormStart,
  FormSubmit,
  StartSession,
  EndSession
}

export type TrackedEvent = CustomTrackedEvent | AmplitudeTrackedEvent

export const allTrackedEvents: {name: string, eventProperties?: {name: string, required: boolean}[]}[] = [
  {
    name: "Login",
    eventProperties: [
      {
        name: "success",
        required: true
      }
    ]
  },
  {
    name: "Logout",
    eventProperties: [
      {
        name: "success",
        required: false
      }
    ]
  },
  {
    name: "StartPipelineSession",
    eventProperties: [
      {
        name: "pipelineJobId",
        required: true
      },
      {
        name: "pipelineName",
        required: true
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "confidence",
        required: true
      }
    ]
  },
  {
    name: "EndPipelineSession",
    eventProperties: [
      {
        name: "pipelineJobId",
        required: true
      },
      {
        name: "pipelineName",
        required: true
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "confidence",
        required: true
      }
    ]
  },
  {
    name: "Proposal",
    eventProperties: [
      {
        name: "garmentId",
        required: true
      },
      {
        name: "pipelineJobId",
        required: true
      },
      {
        name: "pipelineName",
        required: true
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "confidence",
        required: false
      },
      {
        name: "qa",
        required: true
      },
      {
        name: "skipped",
        required: true
      }
    ]
  },
  {
    name: "ProposalQA",
    eventProperties: [
      {
        name: "approvedGarmentIds",
        required: true
      },    
      {
        name: "rejectedGarmentIds",
        required: true
      },
      {
        name: "pipelineJobId",
        required: true
      },
      {
        name: "pipelineName",
        required: true
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "confidence",
        required: false
      },
      {
        name: "qa",
        required: true
      },
      {
        name: "skipped",
        required: true
      }
    ]
  },
  {
    name: "Aggregation",
    eventProperties: [
      {
        name: "index",
        required: true
      },
      {
        name: "datasetId",
        required: true
      },
      {
        name: "garmentId",
        required: true
      },
      {
        name: "aggregations",
        required: true
      },
      {
        name: "totalAggregations",
        required: false
      },
      {
        name: "selectedGarmentIds",
        required: false
      },
      {
        name: "rejectedGarmentIds",
        required: false
      },
      {
        name: "garmentsRejected",
        required: false
      },
      {
        name: "garmentsSelected",
        required: false
      },
      {
        name: "garmentsSeen",
        required: true
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "pipelineJobId",
        required: false
      },
      {
        name: "pipelineName",
        required: false
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "qa",
        required: true
      },
      {
        name: "skipped",
        required: true
      }
    ]
  },
  {
    name: "AggregationQA",
    eventProperties: [
      {
        name: "index",
        required: false
      },
      {
        name: "datasetId",
        required: true
      },
      {
        name: "garmentId",
        required: true
      },
      {
        name: "aggregations",
        required: true
      },
      {
        name: "totalAggregations",
        required: false
      },
      {
        name: "selectedGarmentIds",
        required: false
      },
      {
        name: "rejectedGarmentIds",
        required: false
      },
      {
        name: "garmentsRejected",
        required: false
      },
      {
        name: "garmentsSelected",
        required: false
      },
      {
        name: "garmentsSeen",
        required: true
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "pipelineJobId",
        required: false
      },
      {
        name: "pipelineJobIdsToQA",
        required: false
      },
      {
        name: "pipelineName",
        required: false
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "qa",
        required: true
      },
      {
        name: "skipped",
        required: true
      }
    ]
  },
  {
    name: "AggregationNextPage",
    eventProperties: [
      {
        name: "index",
        required: true
      },
      {
        name: "datasetId",
        required: true
      },
      {
        name: "garmentId",
        required: true
      },
      {
        name: "aggregations",
        required: true
      },
      {
        name: "totalAggregations",
        required: false
      },
      {
        name: "selectedGarmentIds",
        required: false
      },
      {
        name: "rejectedGarmentIds",
        required: false
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "pipelineJobId",
        required: false
      },
      {
        name: "pipelineJobName",
        required: false
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "qa",
        required: true
      },
      {
        name: "pages",
        required: true
      }
    ]
  },
  {
    name: "AggregationQASetGroupRepresentative",
    eventProperties: [
      {
        name: "index",
        required: true
      },
      {
        name: "datasetId",
        required: true
      },
      {
        name: "garmentId",
        required: true
      },
      {
        name: "proposedTimestamp",
        required: true
      },
      {
        name: "jobId",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "qa",
        required: true
      },
    ]
  },
  {
    name: "UserInactive",
    eventProperties: [
      {
        name: "minInactivityDurationSeconds",
        required: true
      }
    ]
  },
  {
    name: "UserActive",
    eventProperties: [
      {
        name: "inactivityDurationSeconds",
        required: true
      }
    ]
  },
  {
    name: "UserActiveHeartbeat",
    eventProperties: [
      {
        name: "heartbeatDurationSeconds",
        required: true
      },
      {
        name: "proposalCount",
        required: true
      },
      {
        name: "proposalQACount",
        required: true
      },
      {
        name: "aggregationCount",
        required: true
      },
      {
        name: "aggregationNextPageCount",
        required: true
      },
      {
        name: "aggregationQACount",
        required: true
      },
      {
        name: "aggregationQASetGroupRepresentativeCount",
        required: true
      },
      {
        name: "hasHadUsefulActivity",
        required: true
      }
    ]
  },
  {
    name: "CatalogueItem",
    eventProperties: [
      {
        name: "garmentId",
        required: true
      },
      {
        name: "proposedBy",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "action",
        required: true
      },
      {
        name: "confidence",
        required: true
      },
      {
        name: "score",
        required: true
      },
      {
        name: "index",
        required: true
      },
      {
        name: "vector",
        required: true
      },
      {
        name: "uuidPublicId",
        required: true
      },
      {
        name: "associatedProposalGroupUuid",
        required: true
      },
      {
        name: "originalProposalGroupUuid",
        required: false
      },
      {
        name: "skipped",
        required: true
      },
      {
        name: "qa",
        required: true
      },
      {
        name: "slipstream",
        required: true
      }
    ]
  },
  {
    name: "CatalogueItemUpdateGroupRepresentative",
    eventProperties: [
      {
        name: "garmentId",
        required: true
      },
      {
        name: "proposedBy",
        required: true
      },
      {
        name: "jobName",
        required: true
      },
      {
        name: "toolType",
        required: true
      },
      {
        name: "toolName",
        required: true
      },
      {
        name: "action",
        required: true
      },
      {
        name: "confidence",
        required: true
      },
      {
        name: "index",
        required: true
      },
      {
        name: "vector",
        required: true
      },
      {
        name: "uuidPublicId",
        required: true
      },
      {
        name: "previousGarmentId",
        required: true
      },
      {
        name: "newGarmentId",
        required: true
      },
      {
        name: "skipped",
        required: true
      },
      {
        name: "qa",
        required: true
      },
      {
        name: "slipstream",
        required: true
      }
    ]
  }
]

export const getUserActiveHeartbeatProperties = () => {
  const eventUserActiveHeartbeat = allTrackedEvents.find(x => x.name === 'UserActiveHeartbeat');
  const eventPropertiesUserActiveHeartbeat = eventUserActiveHeartbeat?.eventProperties;
  const valueNamesToCount = eventPropertiesUserActiveHeartbeat?.map(y => y.name);
  
  return valueNamesToCount;
}

export const minInactivityDurationMinutes = 5;

export const getTrackedEvent = (eventName: string, eventProperties: object) => {
  const validatedEvent = validateEvent(eventName, eventProperties)
  if (validatedEvent)
  {
    return validatedEvent;
  } else {
    console.error(`Validation for TrackedEvent with EventName '${eventName}' failed`);
    return null;
  }
}

export const validateEvent = (eventName: string, eventProperties: object): object | null => {
  let isValid: boolean = true;

  let trackedEvent: object | null = null;

  const foundEventSchemaWithName = allTrackedEvents.find(event => event.name === eventName);
  if (!foundEventSchemaWithName)
  {
    console.error(`Could not get TrackedEvent with EventName '${eventName}'`);
    isValid = false;
  }
  //validate properties on event object before calling Amplitude to prevent duplicate events/typos
  if (!foundEventSchemaWithName?.hasOwnProperty('name'))
  {
    //todo check type of 'name' value
    isValid = false;
  } else {
    const eventNames = allTrackedEvents.map(event => event.name)
    if (!(eventNames.includes(foundEventSchemaWithName['name'])))
    {
      isValid = false;
    }
  }

  if (foundEventSchemaWithName?.hasOwnProperty('eventProperties'))
  {
    foundEventSchemaWithName['eventProperties']?.forEach(prop =>
    {
      if (!prop.hasOwnProperty('name'))
      {
        //todo check type of 'name' value
        isValid = false;
      }
      
      if (!prop.hasOwnProperty('required'))
      {
        //todo check type of 'name' value
        isValid = false;
      }
      
      //if schema property is required and eventProperty with the same name does not exist
      if (prop['required'] && eventProperties && !(Object.getOwnPropertyNames(eventProperties).includes(prop['name'])))
      {
        isValid = false;
      }
    });
  }

  //if the provided name and properties match the found schema
  if (isValid)
  {
    //build the event object - THIS PREVENTS INVALID EVENTS BEING TRACKED
    trackedEvent = {
      eventName,
      eventProperties
    }
  }

  return trackedEvent;
}