import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../../config/apiConfig";
import { getHeaders } from "../../../utils/apiUtils";

import axios from "axios";
import React from "react";
import { showAlert } from "../../common/userFeedback/CustomAlert";

function AddToGroupDialog({ open, onClose, selectedUsers }) {
  const [selectedGroup, setSelectedGroup] = React.useState("");
  const [error, setError] = React.useState("");
  const [userGroups, setUserGroups] = React.useState([]);

  const handleSubmit = async () => {
    // Call API to add selectedUsers to selectedGroup
    if (!selectedGroup) {
      setError("You must select a group.");
      return;
    }

    try {
      const headers = await getHeaders();
      await axios.post(
        `${BASE_URL}tools/admin/add-users-to-group`,
        {
          userIds: selectedUsers,
          groupId: selectedGroup,
        },
        headers
      );
      onClose(); // Close the dialog
    } catch (error) {
      console.error("Failed to add users to group", error);
      setError("Failed to add users to group");
    }
  };
  React.useEffect(() => {
    if (open && selectedUsers.length === 0) {
      alert("Please select at least one user");
      onClose();
    } else {
      fetchUserGroups();
    }
  }, [open, selectedUsers, onClose]);

  const fetchUserGroups = async () => {
    const headers = await getHeaders();
    axios
      .get(`${BASE_URL}tools/admin/user-groups`, headers)
      .then((res) => {
        setUserGroups(res.data.data);
      })
      .catch((err) => {
        console.error("Failed to fetch user groups", err);
        showAlert(error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Add {selectedUsers.length} User{selectedUsers.length == 1 ? "" : "S"} to
        Group
      </DialogTitle>

      <DialogContent>
        <Select
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          fullWidth
          sx={{mt: 1}}
        >
          {userGroups.map((group) => (
            <MenuItem key="{group.id}" value={group.id}>
              {group.bridge_user_group}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add</Button>
      </DialogActions>

      <Typography variant="error" color="error" sx={{mb: 1, mx: 1}}>
        {error}
      </Typography>
    </Dialog>
  );
}

export default AddToGroupDialog;
