import React from "react";
import { Box, Typography, TextField } from "@mui/material";
import AttributeGroupDropdown from "../../../../forms/Dropdowns/AttributeGroupDropdown";
import { useJobConfigurationContext } from "../../../../../contexts/JobConfigurationContext";

const GeneralConfigurationSection = () => {
  const {
    currentGraduationConfidence,
    setCurrentGraduationConfidence,
    numberOfUsers,
    setNumberOfUsers,
    labellingSet,
    setLabellingSet,
    errors,
    setUnsavedChanges,

    activeJob,
  } = useJobConfigurationContext();
  const { jobName, filterMap } = activeJob;
  console.log("id", jobName);
  return (
    <Box>
      <Typography variant="h4">General Configuration</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mt: 1,
        }}
      >
        {false && (
          <>
            <Typography variant="h6">Graduation Confidence</Typography>

            <TextField
              label="Graduation Confidence"
              value={currentGraduationConfidence}
              onChange={(e) => {
                const value = Math.max(0, Math.min(1, Number(e.target.value))); // Ensure between 0 and 1
                setUnsavedChanges(true);
                setCurrentGraduationConfidence(value);
              }}
              disabled={true}
              type="number"
              inputProps={{ min: 0, max: 1, step: 0.01 }}
              variant="outlined"
              margin="normal"
              fullWidth
              sx={{ mb: 2 }}
              error={!!errors.currentGraduationConfidence}
              helperText={errors.currentGraduationConfidence}
            />
          </>
        )}
        {!(jobName == "aggregation") ? (
          <>
            <Typography variant="h6">Number of Users</Typography>
            <TextField
              label="Number of Users"
              value={numberOfUsers}
              onChange={(e) => {
                setUnsavedChanges(true);
                setNumberOfUsers(Number(e.target.value));
              }}
              type="number"
              inputProps={{ min: 1, step: 1 }}
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!errors.numberOfUsers}
              helperText={errors.numberOfUsers}
            />

            <AttributeGroupDropdown
              attributeType={filterMap}
              label="Labelling Set"
              setAttributeGroup={(type, filter) =>
                setLabellingSet({ ...labellingSet, [type]: filter })
              }
              attributeGroup={labellingSet[filterMap]}
              sx={{ mt: 1 }}
              error={!!errors.labellingSet}
              helperText={errors.labellingSet}
            />
          </>
        ) : (
          <Box>
            <Typography variant="body1">
              Aggregation is a unique job that aggregates listings. It does not
              require any labelling set and each garment will only be shown to a
              single user.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GeneralConfigurationSection;
