import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AttributeGroupDropdown from "../../../../forms/Dropdowns/AttributeGroupDropdown";
import { usePipelineContext } from "../../../../../contexts/PipelineContext";
import { useJobConfigurationContext } from "../../../../../contexts/JobConfigurationContext";
function FilterSection({ jobId }) {
  const { orderedJobs, updateJob } = usePipelineContext();
  const { filters, setFilters, setUnsavedChanges } =
    useJobConfigurationContext();

  useEffect(() => {
    const job = orderedJobs.find((job) => job.id === jobId);
    if (job) {
      setFilters(job.filters || {});
    }
  }, [orderedJobs, jobId]);

  const handleFilterChange = (type, newFilters) => {
    console.log("newFilters", newFilters);
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [type]: newFilters };
      // Update the job in context
      updateJob(jobId, { filters: updatedFilters });
      setUnsavedChanges(true);
      return updatedFilters;
    });
  };
  useEffect(() => {
    console.log("filters", filters);
  }, []);

  return (
    <Box>
      <Typography variant="h4">Pre-Filtering</Typography>
      <AttributeGroupDropdown
        attributeType={"IMAGE_CLASS"}
        setAttributeGroup={(type, newFilters) =>
          handleFilterChange(type, newFilters)
        }
        attributeGroup={filters["IMAGE_CLASS"]}
        sx={{
          mt: 1,
        }}
      />
      <AttributeGroupDropdown
        attributeType={"TYPE"}
        setAttributeGroup={(type, newFilters) =>
          handleFilterChange(type, newFilters)
        }
        attributeGroup={filters["TYPE"]}
        sx={{
          mt: 1,
        }}
      />
      <AttributeGroupDropdown
        attributeType={"STYLE"}
        setAttributeGroup={(type, newFilters) =>
          handleFilterChange(type, newFilters)
        }
        attributeGroup={filters["STYLE"]}
        sx={{
          mt: 1,
        }}
      />
    </Box>
  );
}

export default FilterSection;
