import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1
import { getHeaders } from "../../../utils/apiUtils";
import { getProgressColor } from "../../../utils/sharedFunctions";
import { BASE_IMAGE_URL, BASE_URL } from "../../../config/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { showAlert } from "../../../components/common/userFeedback/CustomAlert";
import GroupConfigurationNavigationWidget from "../../../components/navigation/widgets/GroupConfigurationNavigationWidget";
import CopyToDatasetDialog from "../../../components/features/DatasetManagement/CopyToDatasetDialog";
import CopyGarmentsToolbar from "../../../components/common/toolbars/CopyGarmentsToolbar";
import LoadingOverlay from "../../../components/common/userFeedback/LoadingOverlay";
import { ViewButton } from "../../../components/ui/buttons/TableButtons";
import StyledDataGrid from "../../../components/ui/dataViews/StyledDataGrid";
import StyledCard from "../../../components/ui/cards/StyledCard";
import DonutChart from "../../../components/charts/DonutChart";
import ClickableImage from "../../../components/layout/Interactive/Images/ClickableImage";
import { useDataViewColumnContext } from "../../../contexts/DataViewColumnContext";
import { getClientNameFromDatasetID } from "../../../config/clientDataViewConfig";
import { APP_BAR_HEIGHT } from "../../../components/layout/AppBar/AppBarComponent";

function GarmentsCatalogueDashboard() {
  const { id } = useParams(); // Retrieve the ID from the URL if present
  const navigate = useNavigate();
  const theme = useTheme();

  const [garments, setGarments] = useState([]);
  const [selectedGarments, setSelectedGarments] = useState([]);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const [rowCount, setRowCount] = useState(0);
  const [datasetDetails, setDatasetDetails] = useState({});

  const [loading, setLoading] = useState(false);

  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [clientName, setClientName] = useState(null);
  const [garmentDatasetColumns, setGarmentDatasetColumns] = useState([]);
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [copyGarments, setCopyGarments] = useState(false);
  // Chart data
  const [completedGarments, setCompletedGarments] = useState([]);

  useEffect(() => {
    if (id && id !== "all") {
      fetchDatasetDetails();
    } else {
      setDatasetDetails({ name: "All Data" });
    }
  }, [id]);

  useEffect(() => {
    fetchGarments(paginationModel.page, paginationModel.pageSize);
  }, [paginationModel, id]);

  const fetchGarments = async (page, pageSize) => {
    setLoading(true);
    try {
      const headers = await getHeaders();
      const response = await axios.get(
        `${BASE_URL}tools/admin/catalogue/garments/listall`,
        {
          params: {
            dataset_id: id,
            page: page + 1, // MUI DataGrid uses 0-based index, so adjust accordingly
            pageSize,
          },
          ...headers,
        }
      );
      console.log(
        `response.data.data fetchGarments: ${JSON.stringify(
          response.data.data
        )}`
      );
      setGarments(response.data.data);
      setRowCount(response.data.total);
      setLoading(false);
    } catch (err) {
      console.error(`ERROR in fetchGarments: ${err}`);
      setLoading(false);
      showAlert(err);
    }
  };

  const fetchDatasetDetails = async () => {
    const headers = await getHeaders();
    const response = await axios
      .get(`${BASE_URL}tools/admin/datasets/dataset/${id}`, headers)
      .then((res) => {
        console.log(
          `res.data.data fetchDatasetDetails: ${JSON.stringify(res.data.data)}`
        );
        setDatasetDetails(res.data.data);
      })
      .catch((err) => {
        console.error(`ERROR in fetchDatasetDetails: ${err}`);
        showAlert(err);
      });
  };

  const aggregationCountThresholdForCompletion = 50;
  const isAboveAggregationCountThreshold = (x) =>
    x["aggregation_count"] >= aggregationCountThresholdForCompletion;

  const pieChartData = [
    {
      id: 0,
      value: completedGarments ? completedGarments.length : 0,
      label: "COMPLETED",
      color: "purple",
    },
    {
      id: 1,
      value:
        garments && completedGarments
          ? garments.length - completedGarments.length
          : 0,
      label: "PENDING",
      color: "#8C8C8C",
    },
  ];

  useEffect(() => {
    setCompletedGarments(
      garments.filter((x) => isAboveAggregationCountThreshold(x))
    );
  }, [completedGarments]);

  const averageMargin = 0.0;

  const garmentDatasetColumnContext = useDataViewColumnContext()
  useEffect(() => {
    const _clientName = getClientNameFromDatasetID(id)
    setClientName(_clientName);
    if (garmentDatasetColumnContext)
    {
      const config = garmentDatasetColumnContext.clientCatalogueViewConfig;
      if (config)
      {
        setGarmentDatasetColumns(config[_clientName].columns)
      }
    }
  }, [id, garmentDatasetColumnContext])

  return (
    <Box
      sx={{
        height: `calc(100vh - ${APP_BAR_HEIGHT}px)`,
        width: "100%",
        padding: 2,
        paddingTop: 2,
      }}
    >
      <LoadingOverlay open={loading} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2
          }}
        >
          <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="h2">Catalogue Garments from</Typography>
            <Typography variant="h1" sx={{pt: 1}}>{datasetDetails.name ?? '-'}</Typography>
          </Box>
        </Box>

        {/*
        <Autocomplete
          multiple
          options={brands}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => setSelectedBrands(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Brands"
              placeholder="Brands"
            />
          )}
          sx={{
            minWidth: 200,
            ml: 2,
          }}
        />
        <Autocomplete
          multiple
          options={types}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => setSelectedTypes(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Select Types"
              placeholder="Types"
            />
          )}
          sx={{
            minWidth: 200,
            ml: 2,
          }}
        />
        */}
      </Box>

      {/*

      REMOVED Pie Chart, "COMING SOON" Product Highlight and Margins FOR DEMO //TODOWP put this back
      
      <Box>
        <Grid container spacing={2} sx={{
        height: "inherit",
        width: "inherit",
        justifyContent: "space-between",
        display: "flex",
        overflow: "auto",
        marginTop: -3
      }}>
          <Grid item xs={12} md={8}>
            <StyledCard titleVariant="h2" title="PROGRESS">
              <DonutChart data={pieChartData}/>
            </StyledCard>
          </Grid>
          
          <Grid item container xs={12} md={4} direction="row" spacing={2}>
            <Grid item row xs={12} md={12}>
              <StyledCard titleVariant="h2" title="PRODUCT MARGINS">
                <Box>
                  <Typography variant="h3">AVERAGE MARGIN</Typography>

                  <Typography variant="h1" sx={{mt: 1}}>
                    {`${formatCurrency_GBP.format(averageMargin).substring(0,formatCurrency_GBP.format(averageMargin).length-2)}`}{<span style={{display: "inline", color: "grey"}}>{formatCurrency_GBP.format(averageMargin).substring(formatCurrency_GBP.format(averageMargin).length-2, formatCurrency_GBP.format(averageMargin).length)}</span>}
                  </Typography>
                </Box>
              </StyledCard>
            </Grid>
            
            <Grid item row xs={12} md={12}>
              <StyledCard titleVariant="h2" title="PRODUCT HIGHLIGHT"><div>COMING SOON</div></StyledCard>
            </Grid>
          </Grid>
        </Grid>
      </Box>*/}

      <StyledDataGrid
        columns={garmentDatasetColumns}
        rows={garments}
        rowCount={rowCount}
        selectedRows={selectedGarments}
        getRowId={(row) => row.id}
        pagination={true}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        checkboxSelection={checkboxSelection} //TODO change to "true" if you want selection
        copyGarments={copyGarments} //TODO change to "true" if you want "Copy Garments" in toolbar
        keepNonExistentRowsSelected={true}
        selectionCallback={(newSelection) => {
          setSelectedGarments(newSelection);
        }}
        sx={{ maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px - ${6}rem)` }}
        
      />
    </Box>
  );
}

export default GarmentsCatalogueDashboard;
